import { Image } from "react-bootstrap";
import logo from "../images/logoRSA.webp";
import { useEffect, useState } from "react";
import { Chips } from "../../components/Chip";
import HSSelect from "../../components/Select";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useAppDispatch } from "../../hooks/app";
import { getOrgDetailsFromRSA } from "../../redux/actions/orgnization";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";

interface Role {
  role_name: string;
  role_id: number;
  id: number;
  isSubDropDown: boolean;
  isDisabled?: boolean;
  sort_order: number;
}
const ReadySchollAssessmentComponent = (props: {
  roles: Role[];
  org: any;
  onDataChange: Function;
  data?: any;
  isChipSelected?: (e: any) => void;
  orgRole?: any;
}) => {
  const dispatch = useAppDispatch();
  const [mainRole, setMainRole] = useState<Role | null>(null);
  const [sites, setSites] = useState<any | undefined>();
  const [selectedSite, setSelectedSite] = useState<any | undefined>();
  const [mainRoleList, setMainRoleList] = useState<any>([]);
  const [disableAll, setDisableAll] = useState<boolean | false>();

  const org = useSelector((state: RootState) => state.organization);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    const mainRoleData: Role[] = [
      ...props.roles.filter(
        (role) => role.isSubDropDown && role.role_id !== 100000
      ),
    ];
    const rsaData: any = userDetails?.userDetails?.find(
      (userDetail: any) => userDetail?.application.applicationCode === "COR-R"
    );
    const userDetailsSites = rsaData?.siteDetails?.map(
      (site: any) => site.siteId
    );
    const userDataSites = props?.data?.siteDetails?.map(
      (site: any) => site.siteId
    );

    const isSubsetSite =
      userDetailsSites && userDataSites
        ? userDataSites.every((siteId: any) =>
            userDetailsSites.includes(siteId)
          )
        : false;

    const orgRoleToCompare = props.orgRole || 0;
    const selectedRoleToCompare =
      props.data && props.data.selectedRole
        ? props.data.selectedRole.role_id
        : 0;
    const isCreatingUser = !props.data || !props.data.selectedRole;
    const updatedMainRoleList: Role[] = mainRoleData.map((role) => {
      let isDisabled = false;

      if (!isCreatingUser && orgRoleToCompare !== 100000) {
        setDisableAll(!isSubsetSite);

        if (
          orgRoleToCompare === 200002 &&
          (selectedRoleToCompare === 200001 ||
            selectedRoleToCompare === 200004 ||
            selectedRoleToCompare === 200005)
        ) {
          setDisableAll(true);
        }
        if (
          orgRoleToCompare === 200003 &&
          (selectedRoleToCompare === 200001 || selectedRoleToCompare === 200002)
        ) {
          setDisableAll(true);
        }
        if (orgRoleToCompare === 200004) {
          setDisableAll(true);
        }
        if (orgRoleToCompare === 200005) {
          setDisableAll(true);
        }
      }

      if (isCreatingUser) {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 200001:
            isDisabled = role.role_id !== 200002 && role.role_id !== 200001;
            break;
          case 200002:
            isDisabled =
              role.role_id === 200001 ||
              role.role_id === 200004 ||
              role.role_id === 200005;
            break;
          case 200003:
            isDisabled =
              role.role_id !== 200003 &&
              role.role_id !== 200004 &&
              role.role_id !== 200005;
            break;
          case 200004:
            isDisabled = true;
            break;
          case 200005:
            isDisabled = true;
            break;
          default:
            isDisabled = true;
            break;
        }
      } else {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 200001:
            isDisabled =
              (selectedRoleToCompare === 200001 &&
                role.role_id !== 200002 &&
                role.role_id !== 200001) ||
              (selectedRoleToCompare === 200002 &&
                role.role_id !== 200002 &&
                role.role_id !== 200001) ||
              selectedRoleToCompare === 200003 ||
              selectedRoleToCompare === 200004 ||
              selectedRoleToCompare === 200005;
            break;
          case 200002:
            isDisabled =
              selectedRoleToCompare === 200001 ||
              (selectedRoleToCompare === 200002 &&
                role.role_id !== 200002 &&
                role.role_id !== 200003) ||
              (selectedRoleToCompare === 200002 && !isSubsetSite) ||
              (selectedRoleToCompare === 200003 && !isSubsetSite) ||
              (selectedRoleToCompare === 200003 &&
                role.role_id !== 200003 &&
                role.role_id !== 200002) ||
              selectedRoleToCompare === 200004 ||
              selectedRoleToCompare === 200005;
            break;
          case 200003:
            isDisabled =
              selectedRoleToCompare === 200001 ||
              selectedRoleToCompare === 200002 ||
              (selectedRoleToCompare === 200003 && !isSubsetSite) ||
              (selectedRoleToCompare === 200003 &&
                role.role_id !== 200003 &&
                role.role_id !== 200004 &&
                role.role_id !== 200005) ||
              (selectedRoleToCompare === 200004 &&
                role.role_id !== 200003 &&
                role.role_id !== 200004 &&
                role.role_id !== 200005) ||
              (selectedRoleToCompare === 200004 && !isSubsetSite) ||
              (selectedRoleToCompare === 200005 && !isSubsetSite) ||
              (selectedRoleToCompare === 200005 &&
                role.role_id !== 200003 &&
                role.role_id !== 200004 &&
                role.role_id !== 200005);
            break;
          case 200004:
            isDisabled =
              selectedRoleToCompare === 200001 ||
              selectedRoleToCompare === 200002 ||
              selectedRoleToCompare === 200003 ||
              selectedRoleToCompare === 200004 ||
              selectedRoleToCompare === 200005;
            break;
          case 200005:
            isDisabled =
              selectedRoleToCompare === 200001 ||
              selectedRoleToCompare === 200002 ||
              selectedRoleToCompare === 200003 ||
              selectedRoleToCompare === 200004 ||
              selectedRoleToCompare === 200005;
            break;
          default:
            isDisabled = true;
            break;
        }
      }

      return {
        ...role,
        isDisabled,
      };
    });
    setMainRoleList(updatedMainRoleList);
  }, [props]);

  useEffect(() => {
    if (props?.org) {
      const body = {
        hs_id: props?.org.id,
        hs_uuid: props?.org.uuid,
        applications: "COR-R",
        action: "HS_GET_DETAILS",
      };
      dispatch(getOrgDetailsFromRSA(body));
    }
  }, [props?.org]);

  const handleSelectRole = (role: Role) => {
    setMainRole(role);
    setSelectedSite(null);
    props.isChipSelected && props.isChipSelected(true);
  };

  const handleDataChange = (data: any) => {
    props.onDataChange(data);
  };

  useEffect(() => {
    if (props.data) {
      if (props?.data?.application_role) {
        handleSelectRole(props?.data?.application_role);
      }

      if (props?.data?.siteDetails && props.data.siteDetails.length > 0) {
        const data = {
          Site_Name: props.data.siteDetails[0].siteName,
          _id: props.data.siteDetails[0].siteId,
        };
        setSelectedSite(data);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (org?.RSAData?.entity_data) {
      if (userDetails.role === "HighscopeAdmin") {
        setSites(org?.RSAData?.entity_data?.sites);
      }
      const userCorR = userDetails?.userDetails?.find(
        (userDetail: any) => userDetail?.application.applicationCode === "COR-R"
      );
      const userRole = userCorR?.application_role?.role_id;
      if (
        userRole !== 200002 &&
        userRole !== 200003 &&
        userRole !== 200004 &&
        userRole !== 200005
      ) {
        setSites(org?.RSAData?.entity_data?.sites);
      } else {
        const filteredSites = org?.RSAData?.entity_data?.sites?.filter(
          (site: any) =>
            userCorR?.siteDetails.some(
              (userSite: any) => userSite.siteId === site._id
            )
        );
        setSites(filteredSites);
      }
    }
  }, [org?.RSAData?.entity_data, userDetails]);

  useEffect(() => {
    handleDataChange({
      mainRole,
      selectedSite,
    });
  }, [mainRole, selectedSite]);

  return (
    <div className="overflow-auto relative">
      <div className="grid grid-cols-6 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 overflow-auto">
        <Image
          className="px-3 py-2"
          src={logo}
          width={180}
          height={70}
          alt="COR Advantage"
        />
        <div className="grid md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 items-center col-span-5">
          <Chips
            header={"Select Role in the Organization"}
            roles={mainRoleList}
            selectedRole={mainRole}
            onSelectRole={handleSelectRole}
          />
        </div>
      </div>
      {disableAll && (
        <div className="text-yellow-700 font-semibold">
          ***You are not allowed to modify this user's details!!!
        </div>
      )}

      <div className="col-span-12 flex justify-start items-center">
        {mainRole?.role_id !== 100000 && mainRole?.role_id !== 200001 && (
          <div className="w-96 pl-12">
            {mainRole ? (
              <div className="w-full pl-5  text-left">
                <HSSelect
                  label={"Sites"}
                  placeholder="Select Site"
                  options={sites}
                  isMulti={false}
                  closeMenuOnSelect={false}
                  required={true}
                  getOptionLabel={(option: any) => option.Site_Name}
                  getOptionValue={(option: any) => option._id}
                  value={selectedSite}
                  onChange={(obj: any) => {
                    setSelectedSite(obj);
                  }}
                  disabled={disableAll}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {mainRole?.role_id !== 200001 &&
        mainRole?.role_id !== 100000 &&
        selectedSite &&
        selectedSite.userCount >= 5 && (
          <ConfirmationDialog
            open={selectedSite}
            header="User Capacity Reached!"
            message="Sorry, you can only create a maximum of five users for this site"
            onCancel={() => setSelectedSite(null)}
            onConfirm={() => setSelectedSite(null)}
          />
        )}
    </div>
  );
};

export default ReadySchollAssessmentComponent;
