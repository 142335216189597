export const msalConfig = {
  auth: {
    clientId: "" + process.env.REACT_APP_AZURE_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: "" + process.env.REACT_APP_AZURE_SIGNIN_AUTHORITY, // Choose SUSI as your default authority.
    knownAuthorities: ["" + process.env.REACT_APP_AZUR_KNOWN_AUTHORITY], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.REACT_APP_HOST_URL + "/auth", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: process.env.REACT_APP_HOST_URL + "/logout", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["offline_access"],
};
