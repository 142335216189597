import Select, { StylesConfig } from "react-select";
function HSSelect(props: any) {
  const menuTopMargin = props.margin ? props.margin : undefined;
  const colourStyles: StylesConfig<any> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      height: "auto",
      borderRadius: 8,
      borderColor: "#233d81",
      borderWidth: 2,
      minHeight: 43,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#233d81"
          : isFocused
          ? "#1e7ff8"
          : "undefined",
        color: isDisabled
          ? "#ccc"
          : isSelected || isFocused
          ? "#ffffff"
          : "black",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
    menuPortal: (base) => ({ ...base, backgroundColor: "pink" }),
    menu: (base, props) => ({
      ...base,
      marginTop: menuTopMargin,
    }),
  };

  return (
    // <div className={`${props.fullWidth && "w-full"}`}>
    <div>
      <label className="pb-o.5">
        {props.required ? <label className="text-red-700">*</label> : null}
        {props.label}
      </label>
      <Select
      className={props?.className}
        key={props.key}
        placeholder={props.placeholder}
        options={props.options}
        styles={colourStyles}
        getOptionLabel={props.getOptionLabel}
        getOptionValue={props.getOptionValue}
        value={props.value}
        onChange={props.onChange}
        closeMenuOnSelect={
          props.closeMenuOnSelect ? props.closeMenuOnSelect : true
        }
        isDisabled={props.disabled}
        isMulti={props.isMulti}
        required={props.required}
        isOptionDisabled={(option) => option.isDisabled === true}
      />
      {props.error ? (
        <div className={"text-xs text-[#FF0000] ml-2 flex"}>{props.error}</div>
      ) : (
        <div className={"text-xs text-[#FF0000] h-4"} />
      )}
    </div>
  );
}
export default HSSelect;
