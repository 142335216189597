import {
  GET_ALL_APPLICATIONS_FAILED,
  GET_ALL_APPLICATIONS_REQUEST,
  GET_ALL_APPLICATIONS_SUCCESS,
} from "../actionConstants";
import { AppDispatch } from "../store";
import { get } from "./axios";

export const getAllApplications = () => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: GET_ALL_APPLICATIONS_REQUEST });
    const result = await get(`/user-application/all-application`, {}, dispatch);
    dispatch({
      type: GET_ALL_APPLICATIONS_SUCCESS,
      payload: result,
    });
  } catch (err) {
    console.log("Error:", err);
    dispatch({
      type: GET_ALL_APPLICATIONS_FAILED,
    });
  } finally {
  }
};
