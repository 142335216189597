import { Alert } from "@material-tailwind/react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useAppDispatch } from "../hooks/app";
import { hideAlert } from "../redux/actions/app";
import { useEffect } from "react";

const AlertPopup = () => {
  const app = useSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(hideAlert());
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const SuccessIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-6 w-6"
      >
        <path
          fillRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
          clipRule="evenodd"
        />
      </svg>
    );
  };

  const ErrorIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-6 w-6"
      >
        <path
          fillRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
          clipRule="evenodd"
        />
      </svg>
    );
  };

  return (
    <div className="absolute w-full h-[100px] z-1 flex flex-row-reverse">
      <Alert
        color={app.alertType === "success" ? "green" : "red"}
        className="w-[40%] h-20 mt-2 mr-5"
        animate={{
          mount: { y: 0 },
          unmount: { y: 200 },
        }}
        icon={app.alertType === "success" ? <SuccessIcon /> : <ErrorIcon />}
        action={
          <Button
            variant="text"
            color="white"
            size="sm"
            className="!absolute top-6 right-3"
            onClick={() => dispatch(hideAlert())}
          >
            Close
          </Button>
        }
      >
        {app.alertMessage}
      </Alert>
    </div>
  );
};

export default AlertPopup;
