import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import logo from "../images/letterLink.webp";
import { Chips } from "../../components/Chip";
import HSSelect from "../../components/Select";
import TextInput from "../../components/Input";
import { getOrgDetailsFromLL } from "../../redux/actions/orgnization";
import { useAppDispatch } from "../../hooks/app";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface Role {
  role_name: string;
  role_id: number;
  id: number;
  isSubDropDown: boolean;
  isDisabled?: boolean;
  sort_order: number;
}

const LetterLinkUserComponent = (props: {
  roles: Role[];
  onDataChange: Function;
  data?: any;
  isChipSelected?: (e: any) => void;
  orgRole?: any;
  org: any;
}) => {
  const [mainRole, setMainRole] = useState<Role | null>(null);
  const [subRole, setSubRole] = useState<Role | null>(null);
  const [userType, setUserType] = useState<Role | null>(null);
  const [classRoom, setClassroom] = useState<string | undefined>();
  const [subRoleList, setSubRoleList] = useState<any>([]);
  const org = useSelector((state: RootState) => state.organization);
  const dispatch = useAppDispatch();

  const mainRoleList: Role[] = [
    ...props?.roles?.filter(
      (role) => !role.isSubDropDown && role.role_id !== 100000
    ),
    {
      role_name: "Organization Account Manager",
      role_id: 100001,
      id: -1,
      isSubDropDown: false,
      sort_order: 1,
    },
  ];

  useEffect(() => {
    const orgRoleToCompare = props.orgRole || 0;
    const selectedRoleToCompare =
      props.data && props.data.selectedRole
        ? props.data.selectedRole.role_id
        : 0;
    const isCreatingUser = !props.data || !props.data.selectedRole;
    const subRoleData: Role[] = props.roles.filter(
      (role) => role.isSubDropDown
    );

    const updatedSubRoleList: Role[] = subRoleData.map((role) => {
      let isDisabled = false;
      if (isCreatingUser) {
        switch (orgRoleToCompare) {
          case 100001:
            isDisabled = false;
            break;
          case 100004:
            isDisabled = role.role_id === 100001;
            break;

          default:
            break;
        }
      } else {
        switch (orgRoleToCompare) {
          case 100001:
            isDisabled = false;
            break;
          case 100004:
            isDisabled =
              selectedRoleToCompare === 100001 ||
              (selectedRoleToCompare === 100004 && role.role_id !== 100004);
            break;

          default:
            break;
        }
      }
      return {
        ...role,
        isDisabled,
      };
    });
    setSubRoleList(updatedSubRoleList);
  }, [props]);

  useEffect(() => {
    if (props.data) {
      if (props.data.application_role) {
        const { role_id } = props.data.application_role;
        if (role_id === 100001 || role_id == 100004) {
          handleSelectRole(mainRoleList[mainRoleList.length - 1]);
        } else {
          handleSelectRole(props.data.application_role);
        }
        setSubRole(props.data.selectedRole);
        setClassroom(props?.data?.other1);
        const data: any = userTypes.find(
          (userRoles: any) => userRoles.role_id == props?.data?.other2
        );
        setUserType(data);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (props?.org) {
      const body = {
        hs_id: props?.org.id,
        hs_uuid: props?.org.uuid,
        applications: "COR-L",
        action: "HS_GET_DETAILS",
      };
      dispatch(getOrgDetailsFromLL(body));
    }
  }, [props?.org]);

  const userTypes: any = [
    {
      role_name: "Single User",
      role_id: "single_user",
      isDisabled: props.orgRole === 100004,
    },
    {
      role_name: "Multiple User",
      role_id: "multiple_user",
      isDisabled: props.orgRole === 100004,
    },
  ];

  const handleSelectRole = (role: Role) => {
    setMainRole(role);
    props.isChipSelected && props.isChipSelected(true);
  };

  useEffect(() => {
    if (mainRole?.role_id === 100000) {
      setUserType(null);
      setSubRole(null);
    }
  }, [mainRole]);

  const handleDataChange = (data: any) => {
    props.onDataChange(data);
  };

  useEffect(() => {
    handleDataChange({ mainRole, subRole, userType, classRoom });
  }, [mainRole, subRole, userType, classRoom]);

  return (
    <div className="overflow-auto relative">
      <div className="grid grid-cols-6 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 border-b border-b-blue-gray-200 border-b-1">
        <Image
          className="px-3 py-2"
          src={logo}
          width={180}
          height={70}
          alt="COR Advantage"
        />
        <div className="grid md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 items-center col-span-5">
          <Chips
            header={"Select Role for the Organization"}
            roles={mainRoleList}
            selectedRole={mainRole}
            onSelectRole={handleSelectRole}
          />
        </div>
      </div>
      {mainRole?.role_id === 100001 && (
        <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
          <div className="w-full pl-5  text-left">
            <div className="flex flex-col">
              {/* <div className="w-full flex ">
                <div className="text-red-600">*</div>
                Select Role
              </div> */}
              {/* Role */}
              <HSSelect
                label={"Select Role"}
                required={true}
                placeholder="Select Role"
                options={subRoleList}
                getOptionLabel={(option: Role) => option.role_name}
                getOptionValue={(option: Role) => option.role_id}
                value={subRole}
                onChange={(obj: any) => {
                  setSubRole(obj);
                  setClassroom(undefined);
                }}
              />
            </div>
          </div>

          {subRole && (
            <>
              {subRole?.role_id === 100001 && (
                <>
                  <div className="w-full pl-5 text-left mt-1">
                    {/* <div className="w-full flex">
                      <div className="text-red-600">*</div>
                      User Type
                    </div> */}

                    {/* User Type */}
                    <HSSelect
                      label={"User Type"}
                      placeholder="Select User Type"
                      options={userTypes}
                      required={true}
                      getOptionLabel={(option: Role) => option.role_name}
                      getOptionValue={(option: Role) => option.role_id}
                      value={userType}
                      onChange={(obj: any) => {
                        setUserType(obj);
                      }}
                      isDisabled={props?.orgRole && props?.orgRole === 100004}
                    />
                  </div>
                  <div className="w-full pl-5 text-left mt-1">
                    <TextInput
                      label={"Classroom Name"}
                      // required={true}
                      placeholder={"Enter Classroom Name"}
                      value={classRoom}
                      onChange={(e: any) => setClassroom(e.target.value)}
                      required={false}
                      disabled={props?.orgRole === 100004}
                    />
                  </div>
                </>
              )}
              {subRole?.role_id === 100004 && (
                <div className="w-full pl-5 text-left mt-1">
                  <TextInput
                    label={"Classroom Name"}
                    // required={true}
                    placeholder={"Enter Classroom Name"}
                    value={classRoom}
                    onChange={(e: any) => setClassroom(e.target.value)}
                    required={true}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default LetterLinkUserComponent;
