import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import logo from "../images/logoPQA.webp";
import { Chips } from "../../components/Chip";
import HSSelect from "../../components/Select";
import { useAppDispatch } from "../../hooks/app";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { getOrgDetailsFromPQA } from "../../redux/actions/orgnization";

interface Role {
  role_name: string;
  role_id: number;
  id: number;
  isSubDropDown: boolean;
  sort_order: number;
}

const CorePreschoolComponent = (props: {
  data?: any;
  roles: Role[];
  org: any;
  onDataChange: Function;
  isChipSelected?: (e: any) => void;
  orgRole?: any;
}) => {
  const dispatch = useAppDispatch();
  const [mainRole, setMainRole] = useState<Role | null>(null);
  const [sites, setSites] = useState<any | undefined>();
  const [selectedSites, setSelectedSites] = useState<any | undefined>();
  const [classrooms, setClassrooms] = useState<any | undefined>();
  const [selectedClassrooms, setSelectedClassrooms] = useState<
    any | undefined
  >();
  const [disableAll, setDisableAll] = useState<boolean | false>();
  const [mainRoleList, setMainRoleList] = useState<any>([]);

  const org = useSelector((state: RootState) => state.organization);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    const mainRoleData: Role[] = [
      ...props.roles.filter(
        (role) =>
          !role.isSubDropDown &&
          role.role_id !== 100000 &&
          !role.isSubDropDown &&
          role.role_id !== 100005
      ),
    ];

    const PQAData = userDetails?.userDetails?.find(
      (userDetail: any) => userDetail?.application.applicationCode === "COR-P"
    );

    const userDetailsSites = PQAData?.siteDetails?.map(
      (site: any) => site.siteId
    );
    const userDataSites = props?.data?.siteDetails?.map(
      (site: any) => site.siteId
    );
    const userDetailsClasses = PQAData?.classDetails?.map(
      (clss: any) => clss.classId
    );
    const userDataClasses = props?.data?.classDetails?.map(
      (clss: any) => clss.classId
    );

    const isSubsetSite =
      userDetailsSites && userDataSites
        ? userDataSites.every((siteId: any) =>
            userDetailsSites.includes(siteId)
          )
        : false;

    const isSubsetClass =
      userDetailsClasses && userDataClasses
        ? userDataClasses.every((classId: any) =>
            userDetailsClasses.includes(classId)
          )
        : false;

    const orgRoleToCompare = props.orgRole || 0;
    const selectedRoleToCompare =
      props.data && props.data.selectedRole
        ? props.data.selectedRole.role_id
        : 0;

    const isCreatingUser = !props.data || !props.data.selectedRole;
    if (!isCreatingUser && orgRoleToCompare !== 100000) {
      if (orgRoleToCompare === 100002) {
        setDisableAll(!isSubsetSite);
      }
      if (orgRoleToCompare === 100003) {
        setDisableAll(true);
      }
      if (orgRoleToCompare === 100004) {
        setDisableAll(true);
      }
    }
    if (orgRoleToCompare === 100004 && isCreatingUser) {
      const updatedSite = {
        ...PQAData.siteDetails[0],
        _id: PQAData.siteDetails[0]?.siteId,
        Center_Name: PQAData.siteDetails[0]?.siteName,
      };
      setSelectedSites(updatedSite);
    }

    const updatedMainRoleList: Role[] = mainRoleData.map((role) => {
      let isDisabled = false;
      if (isCreatingUser) {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100001:
            isDisabled = role.role_id == 100004;
            break;
          case 100002:
            isDisabled = role.role_id === 100001;

            const updatedSite = {
              ...PQAData.siteDetails[0],
              _id: PQAData.siteDetails[0].siteId,
              Center_Name: PQAData.siteDetails[0].siteName,
            };

            org?.PQAData?.entity_data?.sites?.forEach((site: any) => {
              if (site._id === updatedSite._id) {
                updatedSite.classes = site.classes;
                setSelectedSites(updatedSite);
              }
            });
            break;
          case 100003:
            isDisabled = true;
            break;
          case 100004:
            isDisabled = true;
            break;
          default:
            isDisabled = true;
            break;
        }
      } else {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100001:
            isDisabled = role.role_id === 100004;
            break;
          case 100002:
            isDisabled =
              selectedRoleToCompare === 100001 ||
              (selectedRoleToCompare === 100002 && !isSubsetSite) ||
              role.role_id === 100001;
            break;
          case 100003:
            isDisabled = true;
            break;
          case 100004:
            isDisabled = true;
            break;
          default:
            isDisabled = true;
            break;
        }
      }
      return {
        ...role,
        isDisabled,
      };
    });
    setMainRoleList(updatedMainRoleList);
  }, [props, userDetails]);

  useEffect(() => {
    if (props?.org) {
      const body = {
        hs_id: props?.org.id,
        hs_uuid: props?.org.uuid,
        applications: "COR-P",
        action: "HS_GET_DETAILS",
      };
      dispatch(getOrgDetailsFromPQA(body));
    }
  }, [props?.org]);

  useEffect(() => {
    if (org?.PQAData?.entity_data) {
      setSites(org?.PQAData?.entity_data?.sites);
    }
  }, [org?.PQAData?.entity_data]);

  useEffect(() => {
    if (selectedSites) {
      setClassrooms(selectedSites.classes);
    }
  }, [selectedSites]);

  const handleSelectRole = (role: Role) => {
    setMainRole(role);
    setSelectedSites(null);
    setSelectedClassrooms(null);
    props.isChipSelected && props.isChipSelected(true);
  };

  const handleDataChange = (data: any) => {
    props.onDataChange(data);
  };

  useEffect(() => {
    handleDataChange({
      mainRole,
      selectedSites,
      selectedClassrooms,
    });
  }, [mainRole, selectedSites, selectedClassrooms]);

  useEffect(() => {
    if (props.data) {
      if (props?.data.application_role) {
        handleSelectRole(props.data.application_role);
      }
      if (props?.data?.application_role?.role_id !== 100003) {
        if (props?.data.siteDetails && props.data.siteDetails.length > 0) {
          const data = {
            ...props.data.siteDetails[0],
            Center_Name: props.data.siteDetails[0].siteName,
            _id: props.data.siteDetails[0].siteId,
          };
          setSelectedSites(data);
        }
      } else {
        if (props?.data.siteDetails && props.data.siteDetails.length > 0) {
          const data: any = [];
          for (let i = 0; i < props.data.siteDetails.length; i++) {
            const obj = {
              ...props.data.siteDetails[i],
              Center_Name: props.data.siteDetails[i].siteName,
              _id: props.data.siteDetails[i].siteId,
            };
            data.push(obj);
          }
          setSelectedSites(data);
        }
      }
      if (props?.data?.application_role?.role_id === 100004) {
        if (props?.data.classDetails && props.data.classDetails.length > 0) {
          const data: any = [];
          for (let i = 0; i < props.data.classDetails.length; i++) {
            const obj = {
              ...props.data.classDetails[i],
              Class_Name: props.data.classDetails[i].className,
              _id: props.data.classDetails[i].classId,
            };
            data.push(obj);
          }
          setSelectedClassrooms(data);
        }
      }
    }
  }, [props.data]);

  return (
    <div className="overflow-auto relative">
      <div className="grid grid-cols-6 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 border-b border-b-blue-gray-200 border-b-1">
        <Image
          className="px-3 py-2"
          src={logo}
          width={180}
          height={70}
          alt="COR Advantage"
        />
        <div className="grid md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 items-center col-span-5">
          <Chips
            header={"Select Role for the Organization"}
            roles={mainRoleList}
            selectedRole={mainRole}
            onSelectRole={handleSelectRole}
          />
        </div>
      </div>

      {disableAll && (
        <div className="text-yellow-700 font-semibold">
          ***You are not allowed to modify this user's details!!!
        </div>
      )}

      {mainRole?.role_id == 100002 || mainRole?.role_id === 100003 ? (
        <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
          <div className="w-full pl-5  text-left">
            <div className="flex flex-col">
              <HSSelect
                placeholder={
                  mainRole?.role_id === 100003 ? "Select Sites" : "Select Site"
                }
                label={
                  mainRole?.role_id === 100003 ? "Select Sites" : "Select Site"
                }
                options={sites}
                isMulti={mainRole?.role_id === 100003}
                closeMenuOnSelect={false}
                required={true}
                getOptionLabel={(option: any) => option.Center_Name}
                getOptionValue={(option: any) => option._id}
                value={selectedSites}
                disabled={props.orgRole === 100003 || props.orgRole === 100004}
                onChange={(obj: any) => {
                  setSelectedSites(obj);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {mainRole?.role_id === 100004 && (
        <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
          <div className="w-full pl-5  text-left">
            <div className="flex flex-col">
              <HSSelect
                placeholder="Select Site"
                options={sites}
                isMulti={false}
                label={"Select Site"}
                closeMenuOnSelect={false}
                required={true}
                getOptionLabel={(option: any) => option.Center_Name}
                getOptionValue={(option: any) => option._id}
                value={selectedSites}
                onChange={(obj: any) => {
                  setSelectedSites(obj);
                }}
              />
            </div>
          </div>

          <div className="w-full pl-5  text-left">
            <div className="flex flex-col">
              <HSSelect
                placeholder="Select Classrooms"
                options={classrooms}
                isMulti={true}
                label={"Select Classrooms"}
                closeMenuOnSelect={false}
                required={true}
                getOptionLabel={(option: any) => option.Class_Name}
                getOptionValue={(option: any) => option._id}
                value={selectedClassrooms}
                onChange={(obj: any) => {
                  setSelectedClassrooms(obj);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CorePreschoolComponent;

//    ************************** Code with Hierarchy  **************************

// const CorePreschoolComponent = (props: {
//   data?: any;
//   roles: Role[];
//   org: any;
//   onDataChange: Function;
//   isChipSelected?: (e: any) => void;
//   orgRole?: any;
// }) => {
//   const dispatch = useAppDispatch();
//   const [mainRole, setMainRole] = useState<Role | null>(null);
//   const [subRole, setSubRole] = useState<Role | null>(null);
//   const [userType, setUserType] = useState<Role | null>(null);
//   const [classRoom, setClassroom] = useState<any | undefined>();
//   const [sites, setSites] = useState<any | undefined>();

//   const [selectedSites, setSelectedSites] = useState<any | undefined>();

//   const [classrooms, setClassrooms] = useState<any | undefined>();
//   const [selectedClassrooms, setSelectedClassrooms] = useState<
//     any | undefined
//   >();

//   const org = useSelector((state: RootState) => state.organization);

//   const [disableAll, setDisableAll] = useState<boolean | false>();
//   const [mainRoleList, setMainRoleList] = useState<any>([]);

//   const userDetails = useSelector((state: RootState) => state.user.userDetails);
//   const userEditDetails = useSelector(
//     (state: RootState) => state?.user?.userEditDetails
//   );

//   const [selectedLevel, setSelectedLevel] = useState<any | undefined>();
//   const [subReceipients, setSubReceipients] = useState<any | undefined>();
//   const [serviceCenters, setServiceCenters] = useState<any | undefined>();
//   const [selectedSubReceipient, setSelectedSubReceipient] = useState<
//     any | undefined
//   >();
//   const [selectedServiceCenter, setSelectedServiceCenter] = useState<
//     any | undefined
//   >();

//   const [hierarchy, setHierarchy] = useState<any | undefined>();
//   const [selectedSiteTeacher, setSelectedSiteTeacher] = useState<
//     any | undefined
//   >();
//   const [selectedHierarchy, setSelectedHierarchy] = useState<any | undefined>();

//   const levels = [
//     { label: "Level 1", value: "level1" },
//     { label: "Level 2", value: "level2" },
//     { label: "Level 3", value: "level3" },
//   ];

//   useEffect(() => {
//     const mainRoleData: Role[] = [
//       ...props.roles.filter(
//         (role) =>
//           !role.isSubDropDown &&
//           role.role_id !== 100000 &&
//           !role.isSubDropDown &&
//           role.role_id !== 100005
//       ),
//     ];

//     const PQAData = userDetails?.userDetails?.find(
//       (userDetail: any) => userDetail?.application.applicationCode === "COR-P"
//     );
//     const userDetailsSites = PQAData?.siteDetails?.map(
//       (site: any) => site.siteId
//     );
//     const userDataSites = props?.data?.siteDetails?.map(
//       (site: any) => site.siteId
//     );
//     const userDetailsClasses = PQAData?.classDetails?.map(
//       (clss: any) => clss.classId
//     );
//     const userDataClasses = props?.data?.classDetails?.map(
//       (clss: any) => clss.classId
//     );

//     const isSubsetSite =
//       userDetailsSites && userDataSites
//         ? userDataSites.every((siteId: any) =>
//             userDetailsSites.includes(siteId)
//           )
//         : false;

//     const isSubsetClass =
//       userDetailsClasses && userDataClasses
//         ? userDataClasses.every((classId: any) =>
//             userDetailsClasses.includes(classId)
//           )
//         : false;

//     const orgRoleToCompare = props.orgRole || 0;
//     const selectedRoleToCompare =
//       props.data && props.data.selectedRole
//         ? props.data.selectedRole.role_id
//         : 0;
//     const isCreatingUser = !props.data || !props.data.selectedRole;
//     if (!isCreatingUser && orgRoleToCompare !== 100000) {
//       if (orgRoleToCompare === 100003) {
//         setDisableAll(!isSubsetSite);
//       }
//       if (orgRoleToCompare === 100003 && selectedRoleToCompare === 100001) {
//         setDisableAll(true);
//       }
//       if (orgRoleToCompare === 100004 && selectedRoleToCompare === 100001) {
//         setDisableAll(true);
//       }
//       if (orgRoleToCompare === 100004 && selectedRoleToCompare === 100003) {
//         setDisableAll(true);
//       }
//       if (orgRoleToCompare === 100004 && isSubsetSite) {
//         setDisableAll(!isSubsetClass);
//       }
//       if (orgRoleToCompare === 100004 && !isSubsetSite) {
//         setDisableAll(!isSubsetSite);
//       }
//     }
//     if (orgRoleToCompare === 100004 && isCreatingUser) {
//       const updatedSite = {
//         ...PQAData.siteDetails[0],
//         _id: PQAData.siteDetails[0]?.siteId,
//         Center_Name: PQAData.siteDetails[0]?.siteName,
//       };
//       setSelectedSites(updatedSite);
//     }

//     const updatedMainRoleList: Role[] = mainRoleData.map((role) => {
//       let isDisabled = false;
//       if (isCreatingUser) {
//         switch (orgRoleToCompare) {
//           case 100001:
//             isDisabled = role.role_id == 100004;
//             break;
//           case 100002:
//             isDisabled = role.role_id === 100001 || role.role_id === 100003;

//             const updatedSite = {
//               ...PQAData.siteDetails[0],
//               _id: PQAData.siteDetails[0].siteId,
//               Center_Name: PQAData.siteDetails[0].siteName,
//             };
//             setSelectedSites(updatedSite);
//             break;
//           case 100003:
//             isDisabled = role.role_id !== 100003;
//             break;
//           case 100004:
//             isDisabled = role.role_id !== 100004;
//             break;
//           default:
//             break;
//         }
//       } else {
//         if (
//           userEditDetails.userDetails[0].application_role.role_id === 100001
//         ) {
//           isDisabled = role.role_id === 100004;
//         } else if (
//           userEditDetails.userDetails[0].application_role.role_id === 100002
//         ) {
//           isDisabled = role.role_id === 100001 || role.role_id === 100003;
//         } else if (
//           userEditDetails.userDetails[0].application_role.role_id === 100003
//         ) {
//           isDisabled = role.role_id !== 100003;
//         } else if (
//           userEditDetails.userDetails[0].application_role.role_id === 100004
//         ) {
//           isDisabled = role.role_id !== 100004;
//         }
//       }
//       return {
//         ...role,
//         isDisabled,
//       };
//     });
//     setMainRoleList(updatedMainRoleList);
//   }, [props, userDetails]);

//   useEffect(() => {
//     if (props?.org) {
//       const body = {
//         hs_id: props?.org.id,
//         hs_uuid: props?.org.uuid,
//         applications: "COR-P",
//         action: "HS_GET_DETAILS",
//       };
//       dispatch(getOrgDetailsFromPQA(body));
//     }
//   }, [props?.org]);

//   useEffect(() => {
//     if (org?.PQAData?.entity_data) {
//       setSites(org?.PQAData?.entity_data?.sites);
//       setServiceCenters(org?.PQAData?.entity_data?.serviceCenters);
//     }
//   }, [org?.PQAData?.entity_data, props.data]);

//   useEffect(() => {
//     if (selectedSites) {
//       setClassrooms(selectedSites.classes);
//     }
//   }, [selectedSites]);

//   const handleSelectRole = (role: Role) => {
//     setMainRole(role);
//     setSelectedSites(null);
//     setSelectedClassrooms(null);
//     props.isChipSelected && props.isChipSelected(true);
//   };

//   const handleDataChange = (data: any) => {
//     props.onDataChange(data);
//   };

//   useEffect(() => {
//     handleDataChange({
//       mainRole,
//       selectedSites,
//       selectedClassrooms,
//       selectedServiceCenter,
//       selectedSubReceipient,
//       selectedLevel,
//       selectedHierarchy,
//     });
//   }, [
//     mainRole,
//     selectedSites,
//     selectedClassrooms,
//     selectedServiceCenter,
//     selectedSubReceipient,
//     selectedLevel,
//     selectedHierarchy,
//   ]);

//   useEffect(() => {
//     if (props.data) {
//       if (props?.data.application_role) {
//         handleSelectRole(props.data.application_role);
//       }

//       if (props?.data?.application_role?.role_id === 100001) {
//         switch (props.data.other2) {
//           case "level1":
//             setSelectedLevel({ label: "Level 1", value: "level1" });
//             setSelectedServiceCenter({});
//             setSelectedSubReceipient({});
//             break;
//           case "level2":
//             setSelectedLevel({ label: "Level 2", value: "level2" });
//             const matchingServiceCenterObject =
//               org?.PQAData?.entity_data?.serviceCenters?.filter(
//                 (item: any) => item._id === props.data.other3
//               );
//             setSelectedServiceCenter(matchingServiceCenterObject);
//             setSelectedSubReceipient({});
//             break;
//           case "level3":
//             setSelectedLevel({ label: "Level 3", value: "level3" });
//             const matchingServiceCenter =
//               org?.PQAData?.entity_data?.serviceCenters?.filter(
//                 (item: any) => item._id === props.data.other3
//               );

//             if (matchingServiceCenter && matchingServiceCenter.length > 0) {
//               setSelectedServiceCenter(matchingServiceCenter);
//               const matchingSubReceipient =
//                 matchingServiceCenter[0]?.subReceipients?.filter(
//                   (item: any) => item._id === props.data.other4
//                 );
//               if (matchingSubReceipient && matchingSubReceipient.length > 0) {
//                 setSelectedSubReceipient(matchingSubReceipient);
//               }
//             } else {
//               setSelectedServiceCenter([]);
//               setSelectedSubReceipient([]);
//             }
//             break;

//           default:
//             break;
//         }
//       }
//       if (props?.data.siteDetails && props.data.siteDetails.length > 0) {
//         const data: any = [];
//         if (
//           org?.PQAData?.entity_data?.sites &&
//           org.PQAData.entity_data?.sites.length > 0
//         ) {
//           props.data.siteDetails.forEach((siteDetail: any) => {
//             const matchingSite = org?.PQAData?.entity_data?.sites.find(
//               (orgSite: any) => orgSite?._id === siteDetail?.siteId
//             );
//             if (matchingSite) {
//               data.push(matchingSite);
//             }
//           });
//         }

//         if (props?.data?.application_role?.role_id === 100002) {
//           setSelectedSites(data);
//         } else if (props?.data?.application_role?.role_id === 100003) {
//           setSelectedSites(data);
//           switch (props.data.other2) {
//             case "entity":
//               setSelectedHierarchy({
//                 label: org?.corCData?.entity_data?.Entity_Name,
//                 value: org?.corCData?.entity_data?._id,
//                 level: "entity",
//               });
//               break;
//             case "serviceCenter":
//               const matchingServiceCenterObject = data.find(
//                 (item: any) => item.ServiceCenter.value === props.data.other3
//               );
//               setSelectedHierarchy({
//                 label: matchingServiceCenterObject?.ServiceCenter?.label,
//                 value: matchingServiceCenterObject?.ServiceCenter?.value,
//                 level: "serviceCenter",
//               });

//               break;
//             case "subRecipient":
//               const matchingSubReceipient = data.find(
//                 (item: any) => item.SubReceipient.value === props.data.other3
//               );
//               setSelectedHierarchy({
//                 label: matchingSubReceipient?.SubReceipient?.label,
//                 value: matchingSubReceipient?.SubReceipient?.value,
//                 level: "subRecipient",
//               });
//               break;

//             default:
//               break;
//           }
//         } else if (props?.data?.application_role?.role_id === 100004) {
//           setSelectedSites(data);
//         }
//       }

//       if (props?.data.classDetails && props.data.classDetails.length > 0) {
//         const data: any = [];
//         for (let i = 0; i < props.data.classDetails.length; i++) {
//           const obj = {
//             ...props.data.classDetails[i],
//             Class_Name: props.data.classDetails[i].className,
//             _id: props.data.classDetails[i].classId,
//           };

//           data.push(obj);
//         }

//         setSelectedClassrooms(data);
//       }
//     }
//   }, [props.data, org?.PQAData]);

//   useEffect(() => {
//     if (!org?.PQAData?.entity_data) {
//       return;
//     }
//     const { entity_data } = org.PQAData;
//     const sitesData = entity_data.sites || [];
//     let filteredHierarchyObjects = [];

//     if (selectedSites && selectedSites.length > 0) {
//       setClassrooms(selectedSites[0].classes);

//       filteredHierarchyObjects = sitesData
//         .filter((site: any) =>
//           selectedSites.some((teacher: any) => teacher._id === site._id)
//         )
//         .map((site: any) => {
//           const { label, value } = site?.Center_Heirarchy;
//           let level = "unknown";
//           if (site?.Entity_Id === value) {
//             level = "entity";
//           } else {
//             const matchingServiceCenter = sitesData.find(
//               (ServiceCenter: any) =>
//                 ServiceCenter.ServiceCenter.value === value
//             );
//             if (matchingServiceCenter) {
//               level = "serviceCenter";
//             } else {
//               const matchingSubRecipient = sitesData.find(
//                 (SubReceipient: any) =>
//                   SubReceipient.SubReceipient.value === value
//               );
//               level = matchingSubRecipient ? "subRecipient" : "unknown";
//             }
//           }
//           return { label, value, level };
//         });
//     } else {
//       filteredHierarchyObjects = sitesData?.map((site?: any) => {
//         const { label, value } = site?.Center_Heirarchy;
//         let level = "unknown";
//         if (site.Entity_Id === value) {
//           level = "entity";
//         } else {
//           const matchingServiceCenter = sitesData.find(
//             (ServiceCenter: any) => ServiceCenter.ServiceCenter.value === value
//           );
//           if (matchingServiceCenter) {
//             level = "serviceCenter";
//           } else {
//             const matchingSubRecipient = sitesData.find(
//               (SubReceipient: any) =>
//                 SubReceipient.SubReceipient.value === value
//             );
//             level = matchingSubRecipient ? "subRecipient" : "unknown";
//           }
//         }
//         return { label, value, level };
//       });
//     }
//     setHierarchy(filteredHierarchyObjects);
//     if (selectedServiceCenter) {
//       setSubReceipients(selectedServiceCenter.subReceipients);
//     }
//   }, [selectedSites, selectedServiceCenter, org?.PQAData]);

//   return (
//     <div className="overflow-auto relative">
//       <div className="grid grid-cols-6 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 border-b border-b-blue-gray-200 border-b-1">
//         <Image
//           className="px-3 py-2"
//           src={logo}
//           width={180}
//           height={70}
//           alt="COR Advantage"
//         />
//         <div className="grid md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 items-center col-span-5">
//           <Chips
//             header={"Select Role for the Organization"}
//             roles={mainRoleList}
//             selectedRole={mainRole}
//             onSelectRole={handleSelectRole}
//           />
//         </div>
//       </div>

//       {mainRole?.role_id === 100001 && (
//         <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
//           <div className="w-full text-left">
//             <div className="flex flex-col ml-24 md:ml-0 sm:ml-0 xs:ml-0">
//               <HSSelect
//                 label={"Select Level"}
//                 placeholder="Select Level"
//                 options={levels}
//                 isMulti={false}
//                 closeMenuOnSelect={true}
//                 required={true}
//                 value={selectedLevel}
//                 onChange={(obj: any) => {
//                   setSelectedLevel(obj);
//                   setSelectedServiceCenter(undefined);
//                   setSelectedSubReceipient(undefined);
//                 }}
//               />
//             </div>
//           </div>
//           {selectedLevel && (
//             <>
//               {selectedLevel?.value === "level2" && (
//                 <div className="w-auto pl-2 pb-1 gap-2 text-left mt-1 grid grid-cols-1 md:grid-cols-1 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1">
//                   <div className="w-full mr-28">
//                     <HSSelect
//                       label={"Select Service Center"}
//                       placeholder="Select Service Center"
//                       options={serviceCenters}
//                       isMulti={false}
//                       closeMenuOnSelect={true}
//                       required={true}
//                       getOptionLabel={(option: any) => option.Service_Name}
//                       getOptionValue={(option: any) => option._id}
//                       value={selectedServiceCenter}
//                       onChange={(obj: any) => {
//                         setSelectedServiceCenter(obj);
//                         setSelectedSubReceipient(undefined);
//                       }}
//                     />
//                   </div>
//                 </div>
//               )}

//               {selectedLevel?.value === "level3" && (
//                 <div className="w-auto pl-2 pb-1 gap-2 col-span-2  text-left mt-1 grid grid-cols-2 md:grid-cols-1 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1">
//                   <div className="w-full mr-28">
//                     <HSSelect
//                       label={"Select Service Center"}
//                       placeholder="Select Service Center"
//                       options={serviceCenters}
//                       isMulti={false}
//                       closeMenuOnSelect={true}
//                       required={true}
//                       getOptionLabel={(option: any) => option.Service_Name}
//                       getOptionValue={(option: any) => option._id}
//                       value={selectedServiceCenter}
//                       onChange={(obj: any) => {
//                         setSelectedSubReceipient(null);
//                         setSelectedServiceCenter(obj);
//                       }}
//                     />
//                   </div>
//                   <div className="w-full mr-28">
//                     <HSSelect
//                       label={"Select Sub Receipient"}
//                       placeholder="Select Sub Receipient"
//                       options={subReceipients}
//                       isMulti={false}
//                       closeMenuOnSelect={true}
//                       required={true}
//                       getOptionLabel={(option: any) =>
//                         option.SubReceipient_Name
//                       }
//                       getOptionValue={(option: any) => option._id}
//                       value={selectedSubReceipient}
//                       onChange={(obj: any) => {
//                         setSelectedSubReceipient(obj);
//                       }}
//                     />
//                   </div>
//                 </div>
//               )}
//             </>
//           )}
//         </div>
//       )}

//       {mainRole?.role_id == 100002 || mainRole?.role_id === 100003 ? (
//         <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
//           <div className="w-full pl-5  text-left">
//             <div className="flex flex-col">
//               <div className="w-full flex ">
//                 <div className="text-red-600">*</div>
//                 Select Sites
//               </div>
//               <HSSelect
//                 placeholder="Select Sites"
//                 options={sites}
//                 // isMulti={true}
//                 isMulti={mainRole?.role_id === 100003}
//                 closeMenuOnSelect={false}
//                 required={true}
//                 getOptionLabel={(option: any) => option.Center_Name}
//                 getOptionValue={(option: any) => option._id}
//                 value={selectedSites}
//                 disabled={props.orgRole === 100002 || props.orgRole === 100004}
//                 onChange={(obj: any) => {
//                   setSelectedSites(obj);
//                 }}
//               />
//             </div>
//           </div>
//           <div className="w-full ml-5 mt-4">
//             <HSSelect
//               label={"Select Hierarchy"}
//               placeholder="Select Hierarchy"
//               options={hierarchy}
//               isMulti={false}
//               closeMenuOnSelect={false}
//               required={true}
//               getOptionLabel={(option: any) => option.label}
//               getOptionValue={(option: any) => option.value}
//               value={selectedHierarchy}
//               onChange={(obj: any) => {
//                 setSelectedHierarchy(obj);
//               }}
//             />
//           </div>
//         </div>
//       ) : (
//         ""
//       )}
//       {mainRole?.role_id === 100004 && (
//         <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
//           <div className="w-full pl-5  text-left">
//             <div className="flex flex-col">
//               <div className="w-full flex ">
//                 <div className="text-red-600">*</div>
//                 Select Site
//               </div>
//               <HSSelect
//                 placeholder="Select Site"
//                 options={sites}
//                 isMulti={false}
//                 closeMenuOnSelect={false}
//                 required={true}
//                 getOptionLabel={(option: any) => option.Center_Name}
//                 getOptionValue={(option: any) => option._id}
//                 value={selectedSites}
//                 onChange={(obj: any) => {
//                   setSelectedSites(obj);
//                 }}
//               />
//             </div>
//           </div>

//           <div className="w-full pl-5  text-left">
//             <div className="flex flex-col">
//               <div className="w-full flex ">
//                 <div className="text-red-600">*</div>
//                 Select Classrooms
//               </div>
//               <HSSelect
//                 placeholder="Select Classrooms"
//                 options={classrooms}
//                 isMulti={true}
//                 closeMenuOnSelect={false}
//                 required={true}
//                 getOptionLabel={(option: any) => option.Class_Name}
//                 getOptionValue={(option: any) => option._id}
//                 value={selectedClassrooms}
//                 onChange={(obj: any) => {
//                   setSelectedClassrooms(obj);
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
