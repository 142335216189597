//UserInterface to define the State type for the state of the reducer

import {
  CREATE_ORGNIZATION_FAILED,
  CREATE_ORGNIZATION_SUCCESS,
  DELETE_ORGNIZATION_SUCCESS,
  GET_COR_A_DATA_FAILED,
  GET_COR_A_DATA_SUCCESS,
  GET_COR_C_DATA_FAILED,
  GET_COR_C_DATA_SUCCESS,
  GET_COR_K_DATA_FAILED,
  GET_COR_K_DATA_SUCCESS,
  GET_CU_DATA_FAILED,
  GET_CU_DATA_SUCCESS,
  GET_LL_DATA_FAILED,
  GET_LL_DATA_SUCCESS,
  GET_ORGANIZATION_FAILED,
  GET_ORGANIZATION_SUCCESS,
  GET_PQA_DATA_FAILED,
  GET_PQA_DATA_SUCCESS,
  GET_RSA_DATA_FAILED,
  GET_RSA_DATA_SUCCESS,
  GET_SINGLE_ORG_DATA_FAILED,
  GET_SINGLE_ORG_DATA_SUCCESS,
  GET_USER_DETAILS_FROM_CORA_FAILED,
  GET_USER_DETAILS_FROM_CORA_SUCCESS,
  GET_USER_DETAILS_FROM_CORK_SUCCESS,
  RECOVER_ORGNIZATION_SUCCESS,
  RESET_CREATE_ORG_FLAG,
  RESET_UPDATE_ORG,
  UPDATE_ORGNIZATION_SUCCESS,
} from "../actionConstants";
import { cloneDeep, findIndex } from "lodash";
interface AppInterface {
  orgList: any;
  orgData: any;
  orgnizationCreated: boolean;
  orgnizationData: any;
  totalCount: number;
  updateOrgnization: any;
  corAData: any;
  corKData: any;
  corCData: any;
  RSAData: any;
  PQAData: any;
  LLData: any;
  CUData: any;
  userDetailsCorA: any;
  userDetailsCorK: any;
}

interface Actions {
  payload: any;
  type: string;
  token: string;
}
//State type for defining the state of the reducer
export type State = AppInterface;

//Initial state of the reducer of type State
export const initialState: State = {
  orgList: [],
  orgData: undefined,
  orgnizationCreated: false,
  orgnizationData: undefined,
  totalCount: 0,
  updateOrgnization: undefined,
  corAData: undefined,
  corKData: undefined,
  corCData: undefined,
  RSAData: undefined,
  PQAData: undefined,
  userDetailsCorA: undefined,
  userDetailsCorK: undefined,
  LLData: undefined,
  CUData: undefined,
};
export const OrgnizationReducers = (
  state: State = initialState,
  action: Actions
): State => {
  //switch between action.type
  switch (action.type) {
    //if action is of type SIGN_IN or SIGN_UP return the state by setting token to the payload
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        orgList:
          action.payload.length === 2 ? action.payload[0] : action.payload,
        totalCount: action.payload[1],
      };

    case GET_ORGANIZATION_FAILED:
      return {
        ...state,
        orgList: [],
      };

    case GET_SINGLE_ORG_DATA_SUCCESS:
      return {
        ...state,
        orgData: action.payload,
      };

    case GET_SINGLE_ORG_DATA_FAILED:
      return {
        ...state,
        orgData: undefined,
      };
    case RESET_CREATE_ORG_FLAG:
      return {
        ...state,
        orgnizationCreated: false,
      };
    case CREATE_ORGNIZATION_SUCCESS:
      return {
        ...state,
        orgnizationCreated: true,
      };
    case CREATE_ORGNIZATION_FAILED:
      return {
        ...state,
        orgnizationCreated: false,
      };

    case GET_COR_K_DATA_SUCCESS:
      return {
        ...state,
        corKData: action.payload,
      };
    case GET_USER_DETAILS_FROM_CORA_SUCCESS:
      return {
        ...state,
        userDetailsCorA: action.payload,
      };

    case GET_USER_DETAILS_FROM_CORA_FAILED:
      return {
        ...state,
        userDetailsCorA: undefined,
      };

    case GET_USER_DETAILS_FROM_CORK_SUCCESS:
      return {
        ...state,
        userDetailsCorK: action.payload,
      };

    case GET_COR_K_DATA_FAILED:
      return {
        ...state,
        corKData: undefined,
      };
    case GET_RSA_DATA_SUCCESS:
      return {
        ...state,
        RSAData: action.payload,
      };
    case GET_RSA_DATA_FAILED:
      return {
        ...state,
        RSAData: undefined,
      };
    case GET_LL_DATA_SUCCESS:
      return {
        ...state,
        LLData: action.payload,
      };
    case GET_LL_DATA_FAILED:
      return {
        ...state,
        LLData: undefined,
      };
    case GET_CU_DATA_SUCCESS:
      return {
        ...state,
        CUData: action.payload,
      };
    case GET_CU_DATA_FAILED:
      return {
        ...state,
        CUData: undefined,
      };

    case GET_COR_A_DATA_SUCCESS:
      return {
        ...state,
        corAData: action.payload,
      };
    case GET_COR_A_DATA_FAILED:
      return {
        ...state,
        corAData: undefined,
      };
    case GET_COR_C_DATA_SUCCESS:
      return {
        ...state,
        corCData: action.payload,
      };
    case GET_COR_C_DATA_FAILED:
      return {
        ...state,
        corCData: undefined,
      };

    case GET_PQA_DATA_SUCCESS:
      return {
        ...state,
        PQAData: action.payload,
      };
    case GET_PQA_DATA_FAILED:
      return {
        ...state,
        PQAData: undefined,
      };

    case UPDATE_ORGNIZATION_SUCCESS:
      return {
        ...state,
        updateOrgnization: action.payload,
      };
    case RESET_UPDATE_ORG:
      return {
        ...state,
        updateOrgnization: undefined,
      };
    case DELETE_ORGNIZATION_SUCCESS: {
      const tempList = cloneDeep(state.orgList);
      const index = findIndex(
        tempList,
        (obj: any) => obj.id === action.payload
      );
      if (index >= 0) {
        tempList[index].deletedAt = new Date();
      }
      return {
        ...state,
        orgList: tempList,
      };
    }
    case RECOVER_ORGNIZATION_SUCCESS: {
      const tempList = cloneDeep(state.orgList);
      const index = findIndex(
        tempList,
        (obj: any) => obj.id === action.payload
      );
      if (index >= 0) {
        tempList[index].deletedAt = null;
      }
      return {
        ...state,
        orgList: tempList,
      };
    }

    default:
      return state;
  }
};
