import {
  ArrowDownOnSquareIcon,
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./styles.css";
import DataGrid, { RenderRowProps, Row, SortColumn } from "react-data-grid";
import PaginationTable from "../PaginationTable";
import { Button, Input } from "@material-tailwind/react";
import TextInput from "../Input";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@mui/material";

interface Props {
  rowData: any;
  columnData: any;
  onClickAction?: (type: string, id: any) => void;
  showAction?: boolean;
  onPageChange?: (currentPage: any) => void;
  onRowsPerPageChange?: (rowCountPage: any) => void;
  totalCount?: number;
  onClickSearch?: (serchData: any) => void;
  hidePagination?: boolean;
  hideSearch?: boolean;
}

function GridTable({
  rowData,
  columnData,
  onClickAction,
  showAction = false,
  onPageChange,
  onRowsPerPageChange,
  totalCount,
  onClickSearch,
  hidePagination = false,
  hideSearch = false,
}: Props) {
  const [rowCount, setRowCount] = useState<number>(10);
  const [count, setCount] = useState<number>(10);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>("");
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);

  useEffect(() => {
    totalCount && setCount(totalCount);
  }, [totalCount]);

  const sortedRows = useMemo((): readonly any[] => {
    if (sortColumns.length === 0) return rows;
    const { columnKey, direction } = sortColumns[0];

    let sortedRows: any[] = [...rows];
    if (columnKey == "id") {
      sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
      //     break;
    } else {
      sortedRows = sortedRows.sort((a, b) =>
        a[columnKey].localeCompare(b[columnKey])
      );
    }

    return direction === "DESC" ? sortedRows.reverse() : sortedRows;
  }, [rows, sortColumns]);

  const getAction = (rowData: any = 0) => {
    return (
      <div className="flex self-center justify-center h-full items-center pt-3 cursor-pointer ">
        {!rowData.deletedAt ? (
          <>
            
              <Tooltip title="Edit ">
              <p className="px-1 cursor-pointer">
              <PencilSquareIcon
                className="h-7 w-7 text-blue-800"
                onClick={() => onClickAction && onClickAction("Edit", rowData)}
              />
                </p>
              </Tooltip>
          
            <p className="px-1 cursor-pointer">
                <Tooltip title="Delete ">
              <TrashIcon
                onClick={() =>
                  onClickAction && onClickAction("Delete", rowData)
                }
                className="h-7 w-7 text-red-600"
                />

                </Tooltip>
            </p>
          </>
        ) : (
          <p className="px-1 cursor-pointer">
            <Tooltip title="Recover">
            <ArrowUturnLeftIcon
              onClick={() => onClickAction && onClickAction("Recover", rowData)}
              className="h-7 w-7 text-green-600"
            />
           </Tooltip>
          </p>
        )}
      </div>
    );
  };

  const getViewAction = (rowData: any = 0) => {
    return (
      <div className="flex self-center justify-center h-full items-center pt-3 cursor-pointer">
        <p className="px-1 ">
          <Tooltip title="View">
          <EyeIcon
            onClick={() => onClickAction && onClickAction("View", rowData)}
            className="h-7 w-7 text-blue-800"
          />
          </Tooltip>
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (showAction == true) {
      let filterColData: any = [...columnData];
      // filterColData.push({
      //   key: "view",
      //   name: "Applications/Roles",
      // })
      filterColData.push({ key: "action", name: "Action", width: "160px" });

      let filterRowData = rowData.map((element: any, index: number) => ({
        ...element,
        view: getViewAction(element),
        action: getAction(element),
      }));

      setRows(filterRowData);
      setCols(filterColData);
    } else {
      setRows(rowData);
      setCols(columnData);
    }
  }, [rowData]);

  const [colorScheme, setColorScheme] = useState('light');
  const detectColorScheme = () => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setColorScheme(mediaQuery.matches ? 'dark' : 'light');
  };

  useEffect(() => {
    detectColorScheme(); 
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addListener(detectColorScheme);
       return () => {
      mediaQuery.removeListener(detectColorScheme);
    };
  }, []); 

  const dynamicHeight = Math.min(sortedRows.length * 5, 70) + "vh";
  const renderRow = (key: React.Key, props: RenderRowProps<any>) => {
    return (
      // <>
      //   {props.row.deletedAt ? (
      //     <div key={key}>
      //       <Row
      //         {...props}
      //         className={`${
      //           props.row.deletedAt ? "bg-blue-gray-100 opacity-10" : ""
      //         }`}
      //       />
      //     </div>
      //   ) : (
      //     <Row
      //       {...props}
      //       className={`${
      //         props.row.deletedAt ? "bg-blue-gray-200 opacity-10" : ""
      //       }`}
      //     />
      //   )}
      // </>
      <Row
        {...props}
        className={`${props.row.deletedAt ? getRowClass() : ""}`}
      />
    );
  };
  const getRowClass = () => {
    return colorScheme === 'dark' ? 'bg-blue-gray-50 text-black opacity-10' : 'bg-blue-gray-50 opacity-10';
  };
  return (
    <div className="font-['poppins'] ">
      {!hideSearch && (
        <div className="relative flex justify-end ">
          <TextInput
            type="search"
            placeholder="Search"
            // required={true}
            value={searchString}
            onChange={(e: any) => {
              if (!e.target.value) {
                onClickSearch && onClickSearch("");
                setPage(0);
              }
              setSearchString(e.target.value);
            }}
            className="min-w-[600px] max-w-[800px]  h-[50px]"
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                setPage(0);
                onClickSearch && onClickSearch(searchString);
              }
            }}
          />
          <Button
            size="sm"
            color={"blue"}
            disabled={!searchString}
            onClick={() => {
              setPage(0);
              onClickSearch && onClickSearch(searchString);
            }}
            className="!absolute right-1 top-1 rounded px-4 py-[0.8rem]"
          >
            Search
          </Button>
        </div>
      )}
      <DataGrid
        className="!min-h-[300px] h-[450px] xl:h-[350px]   1xl:h-[370px] lg:h-[328px] md:min-h-[220px] md:max-h-[250px]  -mb-5 -mt-1 "
        columns={cols}
        rows={sortedRows}
        style={sortedRows.length < 10 ? { height: dynamicHeight } : {}}
        // style={{height:dynamicHeight}}
        rowHeight={45}
        headerRowHeight={55}
        onSortColumnsChange={onSortColumnsChange}
        sortColumns={sortColumns}
        renderers={{ renderRow: renderRow }}
      />
      {!hidePagination && (
        <PaginationTable
          count={count}
          onPageChange={(page) => {
            setPage(page);
            onPageChange && onPageChange(page);
          }}
          onRowsPerPageChange={(value) => {
            // setRowCount(value)
            onRowsPerPageChange && onRowsPerPageChange(value);
          }}
          currentPage={page - 1}
          // rowPage={rowCount}
        />
      )}
    </div>
  );
}

export default GridTable;
