import {
  AuthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { NavigationBar } from "./NavigationBar";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Outlet, useLocation } from "react-router-dom";
import { Alert, Button, Spinner, Typography } from "@material-tailwind/react";
import { useEffect } from "react";
import { useAppDispatch } from "../hooks/app";
import {
  requestCompleted,
  requestStarted,
  resetSession,
} from "../redux/actions/app";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getUserDetails } from "../redux/actions/user";
import AlertPopup from "./AlertPopup";
import { loginRequest } from "../authConfig";

export const PageLayout = (props: any) => {
  const { instance, inProgress } = useMsal();
  const activeAccount: any = instance.getActiveAccount();
  const isUserLoggedIn = useIsAuthenticated();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const app = useSelector((state: RootState) => state.app);
  const pathToHide = ["/logout", "/auth"];
  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    if (inProgress !== "none") {
      let message = "Request is in progress";
      if (inProgress === "login") {
        message = "Login is in progress";
      }
      if (inProgress === "startup") {
        message = "Request is in progress";
      }
      if (inProgress === "logout") {
        message = "Logout is in progress";
      }

      dispatch(requestStarted(message));
    } else {
      dispatch(requestCompleted());
    }
  }, [inProgress]);

  useEffect(() => {
    if (inProgress == "none" && isUserLoggedIn) {
      dispatch(getUserDetails(activeAccount.idTokenClaims.oid));
    }
  }, [inProgress, isUserLoggedIn]);

  const showPageLayout = pathToHide.includes(location.pathname);

  const onLoginClick = () => {
    localStorage.clear();
    dispatch(resetSession());
    handleLoginRedirect();
  };

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <div className="flex min-w-full w-screen relative">
      <>
        {!showPageLayout && isUserLoggedIn && location.pathname !== "/" && (
          <div className="">
            <Sidebar />
          </div>
        )}
        <div className="wrapper d-flex flex-column min-vh-100 bg-light w-full min-w-[80vw]">
          {!showPageLayout && (
            <div className="min-h-[8vh] bg-[#264390] pr-12 pt-2">
              <Topbar />
            </div>
          )}
          <div className={`body flex-grow-1 ${isUserLoggedIn&&'p-4' } max-h-[92vh] overflow-hidden bg-gray-300 `}>
            <Outlet context={userDetails} />
          </div>
        </div>
      </>

      {app.loading && (
        <div
          className="absolute w-full h-[100vh] z-1 flex flex-row items-center justify-center "
          style={{ backgroundColor: "rgb(255, 255, 255, 0.8)" }}
        >
          <div className="h-[50px] bg-[#264390] rounded-sm flex flex-row items-center justify-center px-2 shadow-md">
            <Spinner className="h-8 w-8 text-white" color="blue" />
            {app.loadingMessage && (
              <div className="text-white ml-2">{app.loadingMessage}</div>
            )}
          </div>
        </div>
      )}

      {app.alertType && app.alertMessage ? <AlertPopup /> : null}
      {app.sessionExpired && (
        <div
          className="absolute h-[100vh] w-[100%] bg-blue-gray-200  flex items-center justify-center"
          style={{ backgroundColor: "rgb(0,0,0, 0.5)" }}
        >
          <div className=" bg-blue-gray-100 border-4 border-indigo-600 rounded-md px-5 py-3 flex flex-col justify-center items-center">
            <Typography className="font-bold text-4xl text-red-700">
              Your session has expired
            </Typography>

            <Typography className="font-bold text-base text-center">
              Please log in again to continue using app.
            </Typography>

            <Button
              className="mt-3 bg-indigo-800 w-[40%] text-center"
              color="blue"
              onClick={() => onLoginClick()}
            >
              Log In
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
