import { AppReducers } from "./AppReducers";
import { ApplicationReducers } from "./ApplicationsReducers";
import { OrgnizationReducers } from "./OrganizationReducers";
import { UserReducers } from "./UserReducers";

const appReducer: any = {
  user: UserReducers,
  organization: OrgnizationReducers,
  app: AppReducers,
  application: ApplicationReducers,
};

export default appReducer;
