import TablePagination from "@mui/material/TablePagination";
import React, { useEffect, useState } from "react";
interface props {
  count: number;
  currentPage: number;
  onPageChange: (activePage: number) => void;
  onRowsPerPageChange: (rowCount: any) => void;
}

export default function PaginationTable({
  count = 1,
  currentPage,
  onPageChange,
  onRowsPerPageChange,
}: props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // useEffect(() => {

  //   setPage(currentPage)
  //   setRowsPerPage(rowPage)
  // }, [currentPage, rowPage])

  useEffect(() => {
    if (currentPage <= 0) {
      setPage(0);
    } else {
      setPage(currentPage);
    }
  }, [currentPage]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    onPageChange && onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange &&
      onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0);
    onPageChange && onPageChange(1);
  };

  return (
    <TablePagination
      className="flex justify-end -mt-5"
      component="div"
      count={count}
      style={{ display: "flex" }}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel, ":
          {
            "margin-top": "1em",
            "margin-bottom": "1em",
            "font-size": "18px",
          },
      }}
    />
  );
}
