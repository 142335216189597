import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Container } from "react-bootstrap";
import { IdTokenData } from "../components/DataDisplay";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { loginRequest } from "../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { setRedirectType, setRedirectURL } from "../utils";
import { useCookies } from "react-cookie";
import { REDIRECT_URL } from "../utils/constant";

export const Authenticate = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookiesUrl, setRedirectUrlCookie, removeUrlCookie] = useCookies([
    "redirectData",
  ]);

  const { instance, inProgress } = useMsal();
  //   const instance = new PublicClientApplication(msalConfig);
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (searchParams && searchParams.size === 2 && inProgress === "none") {
      const type = searchParams.get("type");
      const redirectUrl: any = searchParams.get("redirectUrl");

      if (type && type === "login") {
        setRedirectCookiees(redirectUrl, type);
        loginForApp();
      } else if (type && type === "logout") {
        setRedirectCookiees(redirectUrl, type);
        instance.logoutRedirect();
      } else if (type && type === "resetPass") {
        setRedirectCookiees(redirectUrl, type);
        openResetPassword();
      }
    } else if (inProgress === "none") {
      removeUrlCookie("redirectData");
      loginForApp();
    }
  }, [searchParams, instance, inProgress]);

  const openResetPassword = () => {
    const authority = process.env.REACT_APP_AZURE_RESET_PASSWORD_AUTHORITY;
    instance.loginRedirect({
      authority: authority,
      scopes: ["offline_access"],
    });
  };

  const setRedirectCookiees = async (redirectUrl: string, type: string) => {
    setRedirectUrlCookie("redirectData", { redirectUrl, type }, { path: "/" });
  };

  const loginForApp = async () => {
    const result = await instance.handleRedirectPromise();
    instance
      .loginRedirect(loginRequest)
      .then((response) => {
        console.log("Login Respnse");
      })
      .catch((error) => console.log("Error in Login", error));
  };

  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Container>
            <div></div>
          </Container>
        ) : (
          <div>No Account</div>
        )}
      </AuthenticatedTemplate>
    </>
  );
};
