import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { USER_ROLES } from "../utils/constant";
import { useNavigate } from "react-router-dom";
export const Dashboard = () => {
  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      imageUrl: "/images/coreA.webp",
      title: "Cor Advantage",
      discription:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      button: "Visit Site",
      code: "COR-A",
      link: process.env.REACT_APP_COR_A_URL + "/login",
    },
    {
      id: 2,
      imageUrl: "/images/cork.webp",
      title: "Cor For Kindergarden",
      discription:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      button: "Visit Site",
      code: "COR-K",
      link: process.env.REACT_APP_COR_K_URL,
    },
    {
      id: 3,
      imageUrl: "/images/classroomCoach.webp",
      title: "Classroom Coach",
      discription:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      button: "Visit Site",
      code: "COR-C",
      link: process.env.REACT_APP_COR_C_URL,
    },
    {
      id: 5,
      imageUrl: "/images/letterLink.webp",
      title: "Letter Links",
      discription:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      button: "Visit Site",
      code: "COR-L",
      link: process.env.REACT_APP_LETTER_LINK_URL,
    },
    {
      id: 4,
      imageUrl: "/images/logoRSA.webp",
      title: "Ready School Assessment",
      discription:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      button: "Visit Site",
      code: "COR-R",
      link: process.env.REACT_APP_RSA_URL + "/login",
    },
    {
      id: 6,
      imageUrl: "/images/logoPQA.webp",
      title: "Program Quality Assessment",
      discription:
        "Reliable, scientifically validated assessment that reflects research-based, field-tested best practices in early childhood education.",
      button: "Visit Site",
      code: "COR-P",
      link: process.env.REACT_APP_COR_P_URL + "/login",
    },
    {
      id: 7,
      imageUrl: "",
      title: "Curriculum",
      discription:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      button: "Visit Site",
      code: "COR-CU",
      link: process.env.REACT_APP_CURR_URL + "/login",
    },
  ];

  const { instance, inProgress } = useMsal();
  const activeAccount: any = instance.getActiveAccount();
  const [idToken, setIdToken] = useState();

  useEffect(() => {
    if (activeAccount && !activeAccount.idToken && inProgress === "none") {
      getTokenResponse();
    } else if (
      activeAccount &&
      activeAccount.idToken &&
      inProgress === "none"
    ) {
      setIdToken(activeAccount.idToken);
    }
  }, [activeAccount, inProgress]);

  useEffect(() => {
    if (userDetails && userDetails.role === USER_ROLES.HIGHSCOPE_ADMIN) {
      navigate("/admin_dashboard", { replace: true });
    }
  }, [userDetails]);

  const getTokenResponse = async () => {
    const tokenResponse: any = await instance.acquireTokenSilent({
      account: activeAccount,
      scopes: ["offline_access"],
    });
    setIdToken(tokenResponse.idToken);
  };

  return (
    <>
      {/* <AuthenticatedTemplate>
        {activeAccount && idToken ? ( */}
      <Container className="h-full bg-white min-w-[82vw] p-4 overflow-auto  ">
        <div className="h-full ">
          <div className="grid   md:grid-cols-1 md:gap-2  sm:grid-cols-1 sm:gap-2   xs:grid-cols-1 xs:gap-2 grid-cols-2 gap-3 min-h-fit max-h-full   ">
            {userDetails?.userDetails.map((element: any, Index: number) => {
              const application: any = data.find(
                (obj) => obj.code === element.application.applicationCode
              );
              return (
                <Card className="shadow-xl dashboard-card">
                  <div className="dashboard-card bg-gray-300 hover:bg-[#264390] flex gap-3 p-2 rounded-md shadow-sm  hover:text-white md:flex-row sm:flex-col xs:flex-col  min-h-[200px] max-h-[220px]">
                    <div className="bg-white w-[35%] rounded-md p-1 ">
                      <Image
                        className=" "
                        src={application?.imageUrl}
                        width={180}
                        height={72}
                        alt={application?.title}
                      />
                    </div>
                    <div className="w-[65%] hover:text-white">
                      <span className="font-bold">{application?.title}</span>
                      <p className="text-md">{application?.discription}</p>
                      <div
                        className="visit-button flex bg-[#264390]  rounded-sm w-[120px] p-1 mb-3 align-middle text-white hover:text-[#264390]"
                        onClick={() => {
                          window.open(
                            application.link + "/?token=" + idToken,
                            "_self"
                          );
                        }}
                      >
                        <ArrowTopRightOnSquareIcon className="w-5 h-5 mr-1 visit-button " />
                        <button>{application?.button}</button>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })}

            <div className="h-4 bg-white" />
          </div>
        </div>
      </Container>

      {/* <Container className="p-3 ">
            <Card className="h-[400px] p-3">
            <Row>
              <Col>
                <Card style={{ width: "18rem", marginBottom: 10 }}>
                  <Card.Img
                    variant="top"
                    src={coreA}
                    style={{
                      height: "10rem",
                      backgroundColor: "#EFFBF8",
                    }}
                    width={"50%"}
                  />
                  <Card.Body>
                    <Card.Title>Core Advantage</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => {
                        window.open(
                          "https://coradvantagedev.azurewebsites.net/login/?token=" +
                            idToken,
                          "_self"
                        );
                      }}
                    >
                      Open
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    variant="top"
                    src={coreK}
                    style={{
                      height: "10rem",
                      backgroundColor: "#EFFBF8",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>Core For Kindergarden</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => {
                        window.open(
                          "https://corforkdev.azurewebsites.net/?token=" +
                            idToken,
                          "_self"
                        );
                      }}
                    >
                      Open
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    variant="top"
                    src={classrromColach}
                    style={{
                      height: "10rem",
                      backgroundColor: "#EFFBF8",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>Classroom Coach</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => {
                        window.open(
                          "https://classroomcoachdev.azurewebsites.net/?token=" +
                            idToken,
                          "_self"
                        );
                      }}
                    >
                      Open
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    variant="top"
                    src={letterLink}
                    style={{
                      height: "10rem",
                      backgroundColor: "#EFFBF8",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>Letter Link</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => {
                        window.open(
                          "https://letterlinks.azurewebsites.net/login/?token=" +
                            idToken,
                          "_self"
                        );
                      }}
                    >
                      Open
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            </Card>
          </Container> */}
      {/* ) : (
          <div>No Account</div>
        )}
      </AuthenticatedTemplate> */}
    </>
  );
};
