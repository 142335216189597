//UserInterface to define the State type for the state of the reducer

import { cloneDeep } from "lodash";
import {
  CREATE_USER_FAILED,
  CREATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_SINGLE_ORG_DATA_USER_SUCCESS,
  GET_USER_DATA_EDIT_FAILED,
  GET_USER_DATA_EDIT_SUCCESS,
  GET_USER_DETAILS_FAILED,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_LIST_FAILED,
  GET_USER_LIST_SUCCESS,
  RECOVER_USER_SUCCESS,
  RESET_CREATE_USER_STATUS,
  RESET_UPDATE_USER_FLAG,
  UPDATE_USER_SUCCESS,
} from "../actionConstants";

interface AppInterface {
  userList: any;
  userDetails: any;
  totalCount: number;
  createdUserStatus: boolean;
  updateUserSuccess: boolean;
  userEditDetails: any;
  orgDropdownUser: any;
}

interface Actions {
  payload: any;
  type: string;
  token: string;
}
//State type for defining the state of the reducer
export type State = AppInterface;

//Initial state of the reducer of type State
export const initialState: State = {
  userList: [],
  userDetails: undefined,
  totalCount: 0,
  createdUserStatus: false,
  updateUserSuccess: false,
  userEditDetails: undefined,
  orgDropdownUser: undefined,
};
export const UserReducers = (state: State = initialState, action: Actions) => {
  //switch between action.type

  switch (action.type) {
    //if action is of type SIGN_IN or SIGN_UP return the state by setting token to the payload
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload[0],
        totalCount: action.payload[1],
      };
    case GET_USER_LIST_FAILED:
      return {
        ...state,
        userList: [],
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case GET_USER_DETAILS_FAILED:
      return {
        ...state,
        userDetails: null,
      };

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createdUserStatus: true,
      };
    case CREATE_USER_FAILED:
      return {
        ...state,
        createdUserStatus: false,
      };
    case RESET_CREATE_USER_STATUS:
      return {
        ...state,
        createdUserStatus: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
      };
    case RESET_UPDATE_USER_FLAG:
      return {
        ...state,
        updateUserSuccess: false,
      };
    case DELETE_USER_SUCCESS: {
      const tempUserList = cloneDeep(state.userList);
      const index = tempUserList.findIndex(
        (obj: any) => obj.id === action.payload
      );
      if (index !== -1) {
        tempUserList[index].deletedAt = new Date();
      }
      return {
        ...state,
        userList: tempUserList,
      };
    }
    case RECOVER_USER_SUCCESS: {
      const tempUserList = cloneDeep(state.userList);
      const index = tempUserList.findIndex(
        (obj: any) => obj.id === action.payload
      );
      if (index !== -1) {
        tempUserList[index].deletedAt = null;
      }
      return {
        ...state,
        userList: tempUserList,
      };
    }
    case GET_USER_DATA_EDIT_SUCCESS:
      return {
        ...state,
        userEditDetails: action.payload,
      };
    case GET_SINGLE_ORG_DATA_USER_SUCCESS:
      return {
        ...state,
        orgDropdownUser: action.payload,
      };
    case GET_USER_DATA_EDIT_FAILED:
      return {
        ...state,
        userEditDetails: null,
      };
    default:
      return state;
  }
};
