import {
  HIDE_ALERT,
  RESET_SESSION,
  SHOW_ALERT,
  START_LOADING,
  STOP_LOADING,
} from "../actionConstants";
import { AppDispatch } from "../store";

export const requestStarted =
  (message: string = "") =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: START_LOADING, payload: { message } });
  };

export const requestCompleted = () => async (dispatch: AppDispatch) => {
  dispatch({ type: STOP_LOADING });
};

export const hideAlert = () => async (dispatch: AppDispatch) => {
  dispatch({ type: HIDE_ALERT });
};

export const showAlert =
  (message: string, type: string) => async (dispatch: AppDispatch) => {
    dispatch({ type: SHOW_ALERT, payload: { message, type } });
  };

export const resetSession = () => async (dispatch: AppDispatch) => {
  dispatch({ type: RESET_SESSION });
};
