import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import logo from "../images/cork.webp";
import { Chips } from "../../components/Chip";
import HSSelect from "../../components/Select";
import { useAppDispatch } from "../../hooks/app";
import {
  getOrgDetailsFromCorK,
  getUserDetailsFromCorK,
} from "../../redux/actions/orgnization";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import GridTable from "../../components/Table";
import { useLocation } from "react-router-dom";

interface Role {
  role_name: string;
  role_id: number;
  id: number;
  isSubDropDown: boolean;
  isDisabled?: boolean;
  sort_order: number;
}

const CoreKindergardenComponent = (props: {
  roles: Role[];
  org: any;
  orgRole?: any;
  onDataChange: Function;
  selectedRoles?: any;
  // siteData?: any
  // classData?: any
  data?: any;
  actionType?: string; //"Add"|"Edit"
  isChipSelected?: (e: any) => void;
}) => {
  const dispatch = useAppDispatch();
  const [mainRole, setMainRole] = useState<Role | null>(null);
  const [subRole, setSubRole] = useState<Role | null>(null);
  const [sites, setSites] = useState<any | undefined>();
  const [selectedSites, setSelectedSites] = useState<any | undefined>([]);
  const [selectedSiteTeacher, setSelectedSiteTeacher] = useState<
    any | undefined
  >();
  const [classrooms, setClassrooms] = useState<any | undefined>();
  const [selectedClassrooms, setSelectedClassrooms] = useState<
    any | undefined
  >();
  const [mainRoleList, setMainRoleList] = useState<any>([]);
  const [disableAll, setDisableAll] = useState<boolean | false>();
  const [selectedUserImport, setSelectedUserImport] = useState<any>("");

  const subRoleList: Role[] = props?.roles.filter((role) => role.isSubDropDown);
  const userDetailsCorK = useSelector(
    (state: RootState) => state.organization.userDetailsCorK
  );

  const org = useSelector((state: RootState) => state.organization);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const [userDetailsCor_K, setUserDetailsCorK] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const mainRoleData: Role[] = [
      ...props.roles.filter(
        (role) => !role.isSubDropDown && role.role_id !== 100000
      ),
    ];

    const corKData = userDetails?.userDetails?.find(
      (userDetail: any) => userDetail?.application.applicationCode === "COR-K"
    );
    const userDetailsSites = corKData?.siteDetails?.map(
      (site: any) => site.siteId
    );
    const userDataSites = props?.data?.siteDetails?.map(
      (site: any) => site.siteId
    );
    const userDetailsClasses = corKData?.classDetails?.map(
      (clss: any) => clss.classId
    );
    const userDataClasses = props?.data?.classDetails?.map(
      (clss: any) => clss.classId
    );

    const isSubsetSite =
      userDetailsSites && userDataSites
        ? userDataSites.every((siteId: any) =>
            userDetailsSites.includes(siteId)
          )
        : false;
    const isSubsetClass =
      userDetailsClasses && userDataClasses
        ? userDataClasses.every((classId: any) =>
            userDetailsClasses.includes(classId)
          )
        : false;
    const orgRoleToCompare = props.orgRole || 0;
    const selectedRoleToCompare =
      props.data && props.data.selectedRole
        ? props.data.selectedRole.role_id
        : 0;
    const isCreatingUser = !props.data || !props.data.selectedRole;
    if (!isCreatingUser && orgRoleToCompare !== 100000) {
      if (orgRoleToCompare === 100001) {
        setDisableAll(!isSubsetSite);
      } else if (
        orgRoleToCompare === 100004 &&
        selectedRoleToCompare === 100001
      ) {
        setDisableAll(true);
      } else if (orgRoleToCompare === 100004 && isSubsetSite) {
        setDisableAll(!isSubsetClass);
      } else if (orgRoleToCompare === 100004 && !isSubsetSite) {
        setDisableAll(!isSubsetSite);
      }
    }
    if (orgRoleToCompare === 0) {
      setDisableAll(true);
    }
    if (orgRoleToCompare === 100004 && isCreatingUser) {
      const updatedSite = {
        ...corKData.siteDetails[0],
        _id: corKData.siteDetails[0].siteId,
        Center_Name: corKData.siteDetails[0].siteName,
      };
      setSelectedSites(updatedSite);
    }

    const updatedMainRoleList: Role[] = mainRoleData.map((role) => {
      let isDisabled = false;
      if (isCreatingUser) {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100001:
            isDisabled = role.role_id === 100002;
            break;
          case 100002:
            isDisabled = false;
            break;
          case 100004:
            isDisabled = role.role_id !== 100004;
            break;
          default:
            isDisabled = true;
            break;
        }
      } else {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100001:
            isDisabled =
              (selectedRoleToCompare === 100001 && role.role_id === 100002) ||
              (selectedRoleToCompare === 100001 && !isSubsetSite) ||
              (selectedRoleToCompare === 100004 && !isSubsetSite) ||
              selectedRoleToCompare === 100002 ||
              (selectedRoleToCompare === 100004 && role.role_id === 100002);
            break;
          case 100002:
            isDisabled = false;
            break;
          case 100004:
            isDisabled =
              selectedRoleToCompare === 100001 ||
              selectedRoleToCompare === 100002 ||
              (selectedRoleToCompare === 100004 && role.role_id !== 100004) ||
              (selectedRoleToCompare === 100004 && !isSubsetSite) ||
              (selectedRoleToCompare === 100004 && !isSubsetClass);
            break;
          default:
            isDisabled = true;
            break;
        }
      }

      return {
        ...role,
        isDisabled,
      };
    });
    setMainRoleList(updatedMainRoleList);
  }, [props, userDetails]);

  useEffect(() => {
    if (userDetailsCorK && !mainRole) {
      setUserDetailsCorK(userDetailsCorK.users);
    }
  }, [userDetailsCorK, !mainRole]);

  useEffect(() => {
    if (mainRole) {
      const filtered: any = userDetailsCorK?.users.filter(
        (user: any) => user.Role == String(selectedUserImport.role_id)
      );
      setUserDetailsCorK(filtered);
    }
  }, [mainRole, userDetailsCorK?.users]);
  const columns = [
    { key: "First_Name", name: "First Name", sortable: true, draggable: true },
    { key: "Last_Name", name: "Last Name", sortable: true, draggable: true },
    { key: "Username", name: "Username", sortable: true, draggable: true },
    { key: "Email_Id", name: "Email Id", sortable: true, draggable: true },
    {
      key: "Phone_Number",
      name: "Phone Number",
      sortable: false,
      draggable: true,
    },
  ];
  useEffect(() => {
    if (props?.org && location.pathname !== "/importUser") {
      const body = {
        hs_id: props?.org.id,
        hs_uuid: props?.org.uuid,
        applications: "COR-K",
        action: "HS_GET_DETAILS",
      };
      dispatch(getOrgDetailsFromCorK(body));
    }
  }, [props?.org]);

  useEffect(() => {
    if (props?.org) {
      const body = {
        hs_id: props?.org.id,
        hs_uuid: props?.org.uuid,
        applications: "COR-K",
        action: "HS_GET_USER_DETAILS",
      };
      dispatch(getUserDetailsFromCorK(body));
    }
  }, [props?.org]);

  const handleSelectRole = (role: Role) => {
    setMainRole(role);
    props.isChipSelected && props.isChipSelected(true);
    setSelectedUserImport("");
  };

  useEffect(() => {
    if (props?.data) {
      if (props.data?.application_role) {
        const { role_id } = props.data?.selectedRole;
        handleSelectRole(props.data?.application_role);
      }

      if (props?.data.siteDetails && props.data.siteDetails.length > 0) {
        const data: any = [];
        for (let i = 0; i < props.data.siteDetails.length; i++) {
          const obj = {
            ...props.data.siteDetails[i],
            Center_Name: props.data.siteDetails[i].siteName,
            _id: props.data.siteDetails[i].siteId,
          };
          data.push(obj);
        }
        if (props?.data?.application_role?.role_id === 100004) {
          setSelectedSiteTeacher(data[0]);
        } else if (props?.data?.application_role?.role_id === 100001) {
          setSelectedSites(data);
        }
      }

      if (props?.data.classDetails && props.data.classDetails.length > 0) {
        const data: any = [];
        for (let i = 0; i < props.data.classDetails.length; i++) {
          const obj = {
            ...props.data.classDetails[i],
            Class_Name: props.data.classDetails[i].className,
            _id: props.data.classDetails[i].classId,
          };
          data.push(obj);
        }
        setSelectedClassrooms(data);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (props?.data && !subRole) {
      const selectedRoles = subRoleList.filter(
        (role: any) => role.role_name === props.data.other1
      );
      const param: Role = selectedRoles[0];
      setSubRole(param);
    }
  }, [subRoleList]);

  const handleDataChange = (data: any) => {
    props.onDataChange(data);
  };

  useEffect(() => {
    handleDataChange({
      mainRole,
      selectedSites,
      selectedSite: selectedSiteTeacher,
      selectedClassrooms,
      subRole,
      selectedUserImport,
    });
  }, [
    mainRole,
    selectedSites,
    selectedSiteTeacher,
    selectedClassrooms,
    subRole,
    selectedUserImport,
  ]);

  useEffect(() => {
    if (org?.corKData?.entity_data) {
      const userCorK = userDetails?.userDetails?.find(
        (userDetail: any) => userDetail?.application.applicationCode === "COR-K"
      );
      if (userDetails.role === "HighscopeAdmin") {
        setSites(org?.corKData?.entity_data?.sites);
      }

      const userRole = userCorK?.application_role?.role_id;
      if (userRole !== 100001 && userRole !== 100004) {
        setSites(org?.corKData?.entity_data?.sites);
      } else {
        const filteredSites = org?.corKData?.entity_data?.sites.filter(
          (site: any) =>
            userCorK?.siteDetails.some(
              (userSite: any) => userSite.siteId === site._id
            )
        );

        setSites(filteredSites);
      }
    }
  }, [org?.corKData?.entity_data, userDetails]);

  useEffect(() => {
    if (selectedSiteTeacher) {
      setClassrooms(selectedSiteTeacher?.classes);
    }
  }, [selectedSiteTeacher]);

  useEffect(() => {
    if (selectedSiteTeacher && sites) {
      const matchedSite = sites.find(
        (site: any) => site._id === selectedSiteTeacher._id
      );

      const userCorK = userDetails?.userDetails?.find(
        (userDetail: any) => userDetail?.application.applicationCode === "COR-K"
      );
      const userRole = userCorK?.application_role?.role_id;

      if (matchedSite) {
        if (userRole !== 100004) {
          setClassrooms(matchedSite.classes);
        } else {
          const filteredClassRooms = matchedSite.classes.filter((clss: any) =>
            userCorK?.classDetails.some(
              (userClass: any) => userClass.classId === clss._id
            )
          );
          setClassrooms(filteredClassRooms);
        }
      }
    }
  }, [selectedSiteTeacher, sites, userDetails]);

  return (
    <div className="overflow-auto relative">
      <div className="grid grid-cols-6 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 border-b border-b-blue-gray-200 border-b-1">
        <Image
          className="px-3 py-2"
          src={logo}
          width={180}
          height={70}
          alt="COR Advantage"
        />
        <div className="grid md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 items-center col-span-5">
          <Chips
            header={"Select Role for the Organization"}
            roles={mainRoleList}
            selectedRole={mainRole}
            onSelectRole={handleSelectRole}
          />
        </div>
      </div>

      {disableAll && (
        <div className="text-yellow-700 font-semibold">
          ***You are not allowed to modify this user's details!!!
        </div>
      )}
      {mainRole?.role_id === 100001 && location?.pathname !== "/importUser" && (
        <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
          <div className="w-full pl-5  text-left">
            <div className="flex flex-col">
              <HSSelect
                label={"Select Sites"}
                placeholder="Select Sites"
                options={sites}
                isMulti={true}
                closeMenuOnSelect={false}
                required={true}
                getOptionLabel={(option: any) => option.Center_Name}
                getOptionValue={(option: any) => option._id}
                value={selectedSites}
                onChange={(obj: any) => {
                  setSelectedClassrooms(null);
                  setSelectedSites(obj);
                }}
                disabled={disableAll || props.orgRole === 100004}
              />
            </div>
          </div>
        </div>
      )}
      {mainRole?.role_id === 100004 && location?.pathname !== "/importUser" && (
        <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
          <div className="w-full pl-5  text-left">
            <div className="flex flex-col">
              <HSSelect
                label={" Select Site"}
                placeholder="Select Site"
                options={sites}
                isMulti={false}
                closeMenuOnSelect={false}
                required={true}
                getOptionLabel={(option: any) => option.Center_Name}
                getOptionValue={(option: any) => option._id}
                value={selectedSiteTeacher}
                onChange={(obj: any) => {
                  setSelectedClassrooms(null);
                  setSelectedSiteTeacher(obj);
                }}
                disabled={disableAll}
              />
            </div>
          </div>

          <div className="w-full pl-5  text-left">
            <div className="flex flex-col">
              <HSSelect
                label={"Select Classrooms"}
                placeholder="Select Classrooms"
                options={classrooms}
                isMulti={true}
                closeMenuOnSelect={false}
                required={true}
                getOptionLabel={(option: any) => option.Class_Name}
                getOptionValue={(option: any) => option._id}
                value={selectedClassrooms}
                onChange={(obj: any) => {
                  setSelectedClassrooms(obj);
                }}
                disabled={!selectedSiteTeacher || disableAll}
              />
            </div>
          </div>

          <div className="w-full pl-5  text-left">
            <div className="flex flex-col">
              <HSSelect
                label={"Teacher Profile"}
                placeholder="Select Teacher Profile"
                options={subRoleList}
                isMulti={false}
                closeMenuOnSelect={true}
                required={true}
                getOptionLabel={(option: Role) => option.role_name}
                getOptionValue={(option: Role) => option.id}
                value={subRole}
                onChange={(obj: any) => {
                  setSubRole(obj);
                }}
                disabled={disableAll}
              />
            </div>
          </div>
        </div>
      )}

      {location?.pathname == "/importUser" && mainRole ? (
        <div className="m-4">
          <HSSelect
            label={"Select Users Import"}
            placeholder="Select Users Import"
            required={true}
            options={userDetailsCor_K}
            disabled={!userDetailsCor_K ? true : false}
            getOptionLabel={(option: any) => option.First_Name}
            getOptionValue={(option: any) => option.Last_Name}
            value={selectedUserImport}
            isMulti={true}
            onChange={(obj: any) => {
              setSelectedUserImport(obj);
            }}
          />
          <GridTable
            rowData={userDetailsCor_K}
            columnData={columns}
            showAction={false}
            hidePagination={true}
            hideSearch={true}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CoreKindergardenComponent;
