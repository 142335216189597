//UserInterface to define the State type for the state of the reducer

import {
  GET_ALL_APPLICATIONS_FAILED,
  GET_ALL_APPLICATIONS_REQUEST,
  GET_ALL_APPLICATIONS_SUCCESS,
} from "../actionConstants";

interface AppInterface {
  appLoading: boolean;
  appList: any;
}

interface Actions {
  payload: any;
  type: string;
  token: string;
}
//State type for defining the state of the reducer
export type State = AppInterface;

//Initial state of the reducer of type State
export const initialState: State = {
  appLoading: false,
  appList: [],
};
export const ApplicationReducers = (
  state: State = initialState,
  action: Actions
) => {
  //switch between action.type
  switch (action.type) {
    //if action is of type SIGN_IN or SIGN_UP return the state by setting token to the payload
    case GET_ALL_APPLICATIONS_REQUEST:
      return {
        ...state,
        appLoading: true,
      };
    case GET_ALL_APPLICATIONS_SUCCESS:
      return {
        ...state,
        appLoading: false,
        appList: action.payload,
      };

    case GET_ALL_APPLICATIONS_FAILED:
      return {
        ...state,
        appLoading: false,
        appList: action.payload,
      };

    default:
      return state;
  }
};
