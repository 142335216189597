import { AZUR_TOKEN, REDIRECT_TYPE, REDIRECT_URL } from "./constant";

export const setRedirectURL = (value: string) => {
  window.localStorage.setItem(REDIRECT_URL, value);
};

export const setRedirectType = (value: string) => {
  window.localStorage.setItem(REDIRECT_TYPE, value);
};

export const clearRedirectSession = () => {
  window.localStorage.removeItem(REDIRECT_URL);
  window.localStorage.removeItem(REDIRECT_TYPE);
};

export const getRedirectData = () => {
  const url = window.localStorage.getItem(REDIRECT_URL);
  const type = window.localStorage.getItem(REDIRECT_TYPE);
  return { url, type };
};

export const setAzurToken = (value: string) => {
  window.localStorage.setItem(AZUR_TOKEN, JSON.stringify(value));
};

export const removeAzurToken = () => {
  window.localStorage.removeItem(AZUR_TOKEN);
};

export const getAzurToken = () => {
  const value: any = window.localStorage.getItem(AZUR_TOKEN);
  if (value) {
    const finalToken = value.substring(1, value.length - 1);
    return finalToken;
  } else {
    return null;
  }
};

export const validateEmailId = (email: string) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (!emailRegex.test(email)) {
    return false;
  } else {
    return true;
  }
};
