import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Container, Image } from "react-bootstrap";
import "react-data-grid/lib/styles.css";

// import Table from "rc-table";
import { Button } from "@material-tailwind/react";
import "../components/DataTable/styles.css";
import { useEffect, useState } from "react";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hooks/app";
import { deleteUser, getUserList, recoverUser } from "../redux/actions/user";
import { USER_ROLES } from "../utils/constant";
import GridTable from "../components/Table";
import BootstrapModal from "../components/BootstrapModal";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../components/Dialogs/ConfirmationDialog";
import { getAllApplications } from "../redux/actions/application";

export default function AdminUserList() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<any>([]);
  
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);
  const [orgId, setOrgId] = useState(0);
  const [searchString, setSearchString] = useState<any>("");
  const [openModal, setOpenModal] = useState<any>(false);
  const [userData, setUserData] = useState<any>(null);
  const [confirmDialog, setConfirmDialog] = useState<any>(false);
  const [recoverConfirmDialog, setRecoverConfirmDialog] = useState<any>(false);
  const [selectedUser, setSelectedUser] = useState<any>(false);
  const navigate = useNavigate();
  const applicationList = useSelector(
    (state: RootState) => state.application.appList
  );
  // const[totalRecords,setTotalRecords]=useState<number>(0)
  const logos: any = {
    "COR-K": "/images/cork.webp",
    "COR-C": "/images/classroomCoach.webp",
    "COR-A": "/images/coreA.webp",
    "COR-R": "/images/logoRSA.webp",
    "COR-L": "/images/letterLink.webp",
    "COR-P": "/images/logoPQA.webp",
    "COR-CU": "/images/logoRSA.webp",
  };

  useEffect(() => {
    dispatch(getAllApplications());
  }, []);

  useEffect(() => {
    if (user.userDetails) {
      var org_id =
        user.userDetails.role === USER_ROLES.HIGHSCOPE_ADMIN
          ? "-1"
          : user.userDetails.orgnization.id;

      // getAllUserList(org_id);
      setOrgId(org_id);
    }
  }, [user.userDetails]);

  useEffect(() => {
    if (user.userList && user.userList.length > 0) {
      const userList = [];
      for (let i = 0; i < user.userList.length; i++) {
        const userObj = user.userList[i];
        const obj = {
          id: userObj.id,
          name: userObj.firstName + " " + userObj.lastName,
          email: userObj.email,
          username: userObj.username,
          middleName: userObj.middleName,
          phoneNumber: userObj.phone,
          role:
            userObj.role === USER_ROLES.HIGHSCOPE_ADMIN
              ? "Highscope Admin"
              : userObj.role === USER_ROLES.HIGHSCOPE_ORG_USER
              ? "Organization Admin"
              : "User",
          orgnization: userObj?.orgnization?.name,
          userDetails: user.userList[i].userDetails,
          orgDetail: {
            id: userObj?.orgnization ? userObj?.orgnization.id : null,
            name: userObj?.orgnization ? userObj?.orgnization?.name : null,
          },
          azurId: userObj.azurId,
          deletedAt: userObj.deletedAt,
        };
        userList.push(obj);
      }
      // setTotalRecords(user.userList[1])setUserDatmenu
      setRows(userList);
    } else {
      setRows([]);
    }
  }, [user.userList]);

  const columns = [
    // { key: "id", name: "ID", sortable: true, draggable: true },
    { key: "name", name: "Name", sortable: true, draggable: true },
    { key: "email", name: "Email", sortable: true, draggable: true },
    { key: "username", name: "Username", sortable: true, draggable: true },
    { key: "role", name: "HighScope Role", sortable: true, draggable: true },
    {
      key: "orgnization",
      name: "Organization",
      sortable: false,
      draggable: true,
    },
    {
      key: "view",
      name: "Applications/Roles",
    },
  ];

  const onClickAction = (type: string, rowData: any) => {
    const id = rowData?.id || rowData?._id;
    if (type === "Edit") {
      if (
        rowData.role === "Highscope Admin" ||
        rowData.userDetails.every(
          (userDetail: any) => userDetail.application_role.role_id === 100005
        )
      ) {
        navigate(`/editHSAdmin/${rowData.id}/admin`);
      } else {
        navigate(`/editUser/${rowData?.id}/admin`);
      }
    } else if (type === "Delete") {
      setSelectedUser(rowData);
      setConfirmDialog(true);
      // const index = rows.findIndex((element: any) => element.id == id);
      // const newData = [...rows];
      // newData.splice(index, 1);
      // setRows(newData);
    } else if (type === "View") {
      setOpenModal(true);
      setUserData(rowData);
    } else if (type === "Recover") {
      setSelectedUser(rowData);
      setRecoverConfirmDialog(true);
    }
  };

  const onDeleteUser = () => {
    dispatch(deleteUser(selectedUser.id));
    setConfirmDialog(false);
    setSelectedUser(undefined);
  };

  const onRestoreUser = () => {
    dispatch(recoverUser(selectedUser.id));
    setRecoverConfirmDialog(false);
    setSelectedUser(undefined);
  };

  const getList = () => {
    const data: any = {
      page: page,
      limit: limit,
      // orgId: orgId,
      searchParam: searchString ? searchString : "",
    };
    if (orgId != 0) {
      dispatch(getUserList(data, orgId));
    }
  };

  useEffect(() => {
    getList();
  }, [page, limit, orgId, searchString]);

  return (
    <AuthenticatedTemplate>
      {activeAccount ? (
        <Container className="h-full bg-white p-4 min-w-[82vw] font-['poppins'] overflow-auto">
          <div className="xl:h-[57vh] 2xl:h-[65vh] 1xl:h-[58vh] lg:h-[55vh] md:h-[55vh]">
            <GridTable
              rowData={rows}
              columnData={columns}
              onClickAction={onClickAction}
              showAction={true}
              onPageChange={(pageNumber) => setPage(pageNumber)}
              onRowsPerPageChange={(limit) => setLimit(limit)}
              totalCount={user.totalCount}
              onClickSearch={(searchKey) => {
                setPage(1);
                setSearchString(searchKey);
              }}
            />

            <BootstrapModal
              isVisble={openModal}
              handleClose={() => setOpenModal(false)}
              modalHeader={
                <>
                  <div className="flex justify-between content-between w-full mx-4  mt-1  font-['poppins']  pt-2">
                    <p className="font-normal text-xl">
                      Username :
                      <span className="font-medium">
                        {" "}
                        {" " + userData?.username}
                      </span>{" "}
                    </p>
                    {/* <p className="flex justify-end">Role:{" "+userData?.userDetails?.application_role?.role_name}</p> */}
                  </div>
                </>
              }
              modalBody={
                <>
                  {/* <div>
                  {userData?.role == "Highscope Admin" ? (
                    <div className="font-['poppins'] ">
                      <div className=" w-full justify-between p-1 mb-3">
                        {applicationList.map((app: any) => (
                          <div key={app.uuid} className="row-span-1 ">
                            <div className="flex justify-between">
                              <label htmlFor="" className="grid items-center">
                                <p className="text-[#264390] font-bold text-[20px] ">
                                  Application Details{" "}
                                </p>
                              </label>
                              00000
                            </div>
                            <div className=" -mt-2 mb-3 grid grid-rows-1 grid-flow-row ">
                              <div className="grid grid-flow-col grid-cols-3 gap-44 ">
                                <span className=" font-semibold col-span-1 text-black text-[18px] ">
                                  Name :
                                  <span className="font-medium ">
                                    {"  " + app.applicationName}
                                  </span>
                                </span>
                                <span className="font-semibold  justify-center  text-black text-[18px]">
                                  Role :
                                  <span className="font-medium ">
                                    {" " +
                                      (app.applicationRole.length > 0
                                        ? app.applicationRole[0].role_name
                                        : "")}
                                  </span>
                                </span>
                               
                              </div>
                            </div>

                            <div className="border-b border-gray-400 p-0 mb-3 -ml-4 -mr-4 pt-2"></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                  <div>
                    {userData?.role === "Highscope Admin" && (
                      <div className="font-['poppins']">
                        <div className="w-full justify-between p-1 mb-3">
                          {applicationList.map((app: any) => (
                            <div key={app.uuid} className="row-span-1">
                              <div className="flex justify-between">
                                <label htmlFor="" className="grid items-center">
                                  <p className="text-[#264390] font-bold text-[20px] ">
                                    Application Details
                                  </p>
                                </label>
                                <Image
                                  className="px-3 pt-0 -mb-3"
                                  src={logos[app.applicationCode]}
                                  width={140}
                                  height={50}
                                  alt={app.applicationName}
                                />
                              </div>
                              <div className="-mt-2 mb-3 grid grid-rows-1 grid-flow-row">
                                <div className="grid grid-flow-col grid-cols-3 gap-44">
                                  <span className="font-semibold col-span-1 text-black text-[18px]">
                                    Name :
                                    <span className="font-medium">
                                      {"  " + app.applicationName}
                                    </span>
                                  </span>
                                  <span className="font-semibold justify-center text-black text-[18px]">
                                    Role :
                                    <span className="font-medium">
                                      Highscope Admin
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className="border-b border-gray-400 p-0 mb-3 -ml-4 -mr-4 pt-2"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {userData?.userDetails?.map((user: any) => (
                    <div className="font-['poppins'] ">
                      <div className="flex  justify-between">
                        <p className="text-[#264390] font-bold text-[20px]">
                          Application Details{" "}
                        </p>
                        <div className="">
                          <Image
                            className="px-3 py-0"
                            src={logos[user.application.applicationCode]}
                            width={140}
                            height={50}
                            alt={user.application?.applicationName}
                          />
                        </div>
                      </div>

                      <div className=" flex w-[100%]  p-1 mb-3 items-center ">
                        <div className=" w-[35%]">
                          <span className="font-semibold  text-black text-[18px] ">
                            Name :
                            <span className="font-medium ">
                              {"  " + user.application?.applicationName}
                            </span>
                          </span>
                        </div>
                        <div className=" w-[45%]">
                          <span className="font-semibold   text-black text-[18px] ml-[10px] first-letter:">
                            Role :
                            <span className="font-medium ">
                              {" "}
                              {"  " + user.application_role?.role_name}
                            </span>
                          </span>
                        </div>
                      </div>
                      {userData.userDetails.every(
                        (userDetail: any) =>
                          userDetail.application_role.role_id === 100005
                      ) ? (
                        <div>
                          <div>
                            <p className="text-[#264390] font-bold text-[20px]">
                              Organization Details
                            </p>
                            <div className="grid grid-cols-3 gap-3 -mt-3 text-black">
                              {userData.userDetails.map(
                                (userDetail: any, index: any) =>
                                  userDetail.orgList.map(
                                    (org: any, orgIndex: any) => (
                                      <div
                                        key={`${index}-${orgIndex}`}
                                        className="bg-gray-300 pt-2 pb-0 -mb-0 rounded-md "
                                      >
                                        <p className="font-semibold ml-4">
                                          Organization Name :{" "}
                                          <span className="font-normal">
                                            {org.name}
                                          </span>
                                        </p>
                                        {/* <p className="font-semibold ml-4 -mt-2">
                                        Organization Id :{" "}
                                        <span className="font-normal">
                                          {org.id}
                                        </span>
                                      </p> */}
                                      </div>
                                    )
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {userData.role == "Highscope Admin" ||
                      userData.userDetails.every(
                        (userDetail: any) =>
                          userDetail.application_role.role_id === 100005
                      ) ? (
                        ""
                      ) : (
                        <div>
                          {user?.siteDetails?.length > 0 && (
                            <div>
                              <p className="text-[#264390] font-bold text-[20px]">
                                Site Details
                              </p>
                              <div className="grid grid-cols-3 gap-3 -mt-3 text-black">
                                {user?.siteDetails.map((site: any) => (
                                  <div className="bg-gray-300 p-1 pb-0 -mb-0 rounded-md ">
                                    <p className="font-semibold ml-4">
                                      Site Name :{" "}
                                      <span className="font-normal">
                                        {site?.siteName}
                                      </span>
                                    </p>
                                    <p className="font-semibold ml-4 -mt-2">
                                      Site Id :{" "}
                                      <span className="font-normal">
                                        {site?.siteId}
                                      </span>
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          {user?.classDetails?.length > 0 && (
                            <div className="mt-3 mb-4">
                              <p className="text-[#264390] font-bold text-[20px]">
                                Class Details
                              </p>
                              <div className="grid grid-cols-3 gap-3 -mt-3 text-black">
                                {user?.classDetails.map((classData: any) => (
                                  <div className="bg-gray-300 p-1 pb-0 -mb-0 rounded-md ">
                                    <p className="font-semibold ml-4">
                                      Class Name :{" "}
                                      <span className="font-normal">
                                        {classData.className}
                                      </span>
                                    </p>
                                    <p className="font-semibold ml-4 -mt-2">
                                      Class Id :{" "}
                                      <span className="font-normal">
                                        {classData.classId}
                                      </span>
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <div className="border-b border-gray-400 p-0 mb-3 -ml-4 -mr-4 pt-2"></div>
                    </div>
                  ))}
                </>
              }
              // footerClass="bg-red-500"
              footerBody={
                <>
                  <div className=" rounded-md text-[#264390] mr-2 ">
                    <Button
                      className="bg-white text-[18px] py-1 px-8 "
                      variant="gradient"
                      color="white"
                      type="submit"
                      onClick={() => setOpenModal(null)}
                    >
                      Close
                    </Button>
                  </div>
                </>
              }
            />
            {selectedUser && confirmDialog && (
              <ConfirmationDialog
                open={confirmDialog}
                // message={`Are you sure? you want to delete "${selectedUser.name}" user?`}
                message={`Are you sure you want to delete “${selectedUser.name}”?`}
                onConfirm={() => onDeleteUser()}
                onCancel={() => setConfirmDialog(false)}
              />
            )}
            {selectedUser && recoverConfirmDialog && (
              <ConfirmationDialog
                open={recoverConfirmDialog}
                message={`Are you sure? you want to recover "${selectedUser.name}" user.`}
                onConfirm={() => onRestoreUser()}
                onCancel={() => setRecoverConfirmDialog(false)}
              />
            )}
          </div>
        </Container>
      ) : null}
    </AuthenticatedTemplate>
  );
}
