import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
const HSDialog = (props: any) => {
  return (
    <>
      <Dialog
      className="!bg-transparent"
        open={props.open}
        size={props.size || "md"}
        handler={props.handleOpen}
      >
        <DialogHeader>{props.header}</DialogHeader>
        <DialogBody>{props.message}</DialogBody>
        <DialogFooter>
          {/* <Button
            variant="text"
            color="red"
            onClick={() => props.handleOpen(null)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button> */}
          <Button
            variant="gradient"
            color="green"
            onClick={() => props.handleOpen(null)}
          >
            <span>ok</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default HSDialog;
