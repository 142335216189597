import React from "react";

function TextInput(props: any) {
  return (
    <div>
      <div className="mb-1  ">
        {props.label ? (
          <div className="w-full flex">
            {props.required && <div className="text-red-600">*</div>}
            {props.label}
          </div>
        ) : null}
        <input
        
          // placeholder={`First Name`${required?'*':''}}
          placeholder={`${props.placeholder} ${props.required ? "*" : ""}`}
          value={props.value}
          required={props.required}
          disabled={props.disabled}
          style={{ color: props.disabled ? "#A0A0A0" : "inherit" }}
          className={`border-2 w-full h-[45px] px-2  outline-blue-500 border-[#264390] rounded-lg bg-transparent ${props.className} required-field`}
          // onClick={}
          onChange={props.onChange}
          type={props.type}
          readOnly={props?.readOnly || false}
          onKeyDown={props?.onKeyDown}
        />

        {props.error ? (
          <div className={"text-xs text-[#FF0000] ml-2 flex"}>
            {props.error}
          </div>
        ) : (
          <div className={"text-xs text-[#FF0000] h-4"} />
        )}
      </div>
    </div>
  );
}
export default TextInput;
