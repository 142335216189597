import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { clearRedirectSession, removeAzurToken } from "../utils";

export const LogoutUser = () => {
  const { instance, inProgress } = useMsal();
  const activeAccount: any = instance.getActiveAccount();
  const [isRedirected, setRedirected] = useState(false);
  const [cookiesUrl, setRedirectUrlCookie, removeUrlCookie] = useCookies([
    "redirectData",
  ]);
  useEffect(() => {
    if (inProgress === "none") {
      console.log("activeAccount >>11111", activeAccount, cookiesUrl);
      openNextApp();
    }
    return () => {
      clearRedirectSession();
    };
  }, [activeAccount, inProgress]);

  const openNextApp = () => {
    removeAzurToken();
    if (
      cookiesUrl.redirectData &&
      cookiesUrl.redirectData.redirectUrl &&
      cookiesUrl.redirectData.type
    ) {
      setRedirected(true);
      console.log(
        "cookiesUrl.redirectData.redirectUrl",
        cookiesUrl.redirectData.redirectUrl
      );
      window.open(cookiesUrl.redirectData.redirectUrl, "_self");

      removeUrlCookie("redirectData");
    } else {
      if (!isRedirected) {
        window.open("/", "_self");
      }
    }
  };

  return <div>Logeed out Successfully</div>;
};
