import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";

interface Props {
  open: boolean;
  size?: any;
  header?: string;
  message: string;
  onConfirm: any;
  onCancel: any;
}

const ConfirmationDialog = (props: Props) => {
  const { open, size, header, message, onConfirm, onCancel } = props;

  return (
    <>
      <Dialog open={open} size={"xs"} handler={onConfirm}>
        <DialogHeader>{header ? header : "Alert"}</DialogHeader>
        <DialogBody>{message}</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => onCancel(null)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => onConfirm(null)}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
