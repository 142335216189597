export const STOP_LOADING = "STOP_LOADING";
export const START_LOADING = "START_LOADING";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";
export const SESSION_EXPIRED_ERROR = "SESSION_EXPIRED_ERROR";
export const RESET_SESSION = "RESET_SESSION";

export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED";

export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED";

export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_FAILED = "GET_ORGANIZATION_FAILED";
export const GET_SINGLE_ORG_DATA_SUCCESS = "GET_SINGLE_ORG_DATA_SUCCESS";
export const GET_SINGLE_ORG_DATA_FAILED = "GET_SINGLE_ORG_DATA_FAILED";

export const GET_SINGLE_ORG_DATA_USER_SUCCESS =
  "GET_SINGLE_ORG_DATA_USER_SUCCESS";
export const GET_SINGLE_ORG_DATA_USER_FAILED =
  "GET_SINGLE_ORG_DATA_USER_FAILED";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";

export const RESET_CREATE_USER_STATUS = "RESET_CREATE_USER_STATUS";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const RESET_UPDATE_USER_FLAG = "RESET_UPDATE_USER_FLAG";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const RECOVER_USER_REQUEST = "RECOVER_USER_REQUEST";
export const RECOVER_USER_SUCCESS = "RECOVER_USER_SUCCESS";
export const RECOVER_USER_FAILED = "RECOVER_USER_FAILED";

export const CREATE_ORGNIZATION_REQUEST = "CREATE_ORGNIZATION_REQUEST";
export const CREATE_ORGNIZATION_SUCCESS = "CREATE_ORGNIZATION_SUCCESS";
export const CREATE_ORGNIZATION_FAILED = "CREATE_ORGNIZATION_FAILED";

export const CREATE_ORGNIZATION_LOG_REQUEST = "CREATE_ORGNIZATION_LOG_REQUEST";
export const CREATE_ORGNIZATION_LOG_SUCCESS = "CREATE_ORGNIZATION_LOG_SUCCESS";
export const CREATE_ORGNIZATION_LOG_FAILED = "CREATE_ORGNIZATION_LOG_FAILED";

export const UPDATE_ORGNIZATION_REQUEST = "UPDATE_ORGNIZATION_REQUEST";
export const UPDATE_ORGNIZATION_SUCCESS = "UPDATE_ORGNIZATION_SUCCESS";
export const UPDATE_ORGNIZATION_FAILED = "UPDATE_ORGNIZATION_FAILED";
export const RESET_UPDATE_ORG = "RESET_UPDATE_ORG";

export const DELETE_ORGNIZATION_REQUEST = "DELETE_ORGNIZATION_REQUEST";
export const DELETE_ORGNIZATION_SUCCESS = "DELETE_ORGNIZATION_SUCCESS";
export const DELETE_ORGNIZATION_FAILED = "DELETE_ORGNIZATION_FAILED";

export const RECOVER_ORGNIZATION_REQUEST = "RECOVER_ORGNIZATION_REQUEST";
export const RECOVER_ORGNIZATION_SUCCESS = "RECOVER_ORGNIZATION_SUCCESS";
export const RECOVER_ORGNIZATION_FAILED = "RECOVER_ORGNIZATION_FAILED";

export const GET_ORGNIZATION_DATA_SUCCESS = "GET_ORGNIZATION_DATA_SUCCESS";
export const GET_ORGNIZATION_DATA_FAILED = "GET_ORGNIZATION_DATA_FAILED";

export const RESET_CREATE_ORG_FLAG = "RESET_CREATE_ORG_FLAG";

export const GET_ALL_APPLICATIONS_REQUEST = "GET_ALL_APPLICATIONS_REQUEST";
export const GET_ALL_APPLICATIONS_SUCCESS = "GET_ALL_APPLICATIONS_SUCCESS";
export const GET_ALL_APPLICATIONS_FAILED = "GET_ALL_APPLICATIONS_FAILED";
export const GET_USER_DETAILS_FROM_CORA_SUCCESS =
  "GET_USER_DETAILS_FROM_CORA_SUCCESS";
export const GET_USER_DETAILS_FROM_CORA_FAILED =
  "GET_USER_DETAILS_FROM_CORA_FAILED";

export const GET_USER_DETAILS_FROM_CORK_SUCCESS =
  "GET_USER_DETAILS_FROM_CORK_SUCCESS";
export const GET_USER_DETAILS_FROM_CORK_FAILED =
  "GET_USER_DETAILS_FROM_CORK_FAILED";
export const GET_COR_A_DATA_SUCCESS = "GET_COR_A_DATA_SUCCESS";
export const GET_COR_A_DATA_FAILED = "GET_COR_A_DATA_FAILED";
export const GET_COR_K_DATA_SUCCESS = "GET_COR_K_DATA_SUCCESS";
export const GET_COR_K_DATA_FAILED = "GET_COR_K_DATA_FAILED";
export const GET_COR_C_DATA_SUCCESS = "GET_COR_C_DATA_SUCCESS";
export const GET_COR_C_DATA_FAILED = "GET_COR_C_DATA_FAILED";
export const GET_RSA_DATA_SUCCESS = "GET_RSA_DATA_SUCCESS";
export const GET_RSA_DATA_FAILED = "GET_RSA_DATA_FAILED";
export const GET_LL_DATA_SUCCESS = "GET_LL_DATA_SUCCESS";
export const GET_LL_DATA_FAILED = "GET_LL_DATA_FAILED";
export const GET_CU_DATA_SUCCESS = "GET_CU_DATA_SUCCESS";
export const GET_CU_DATA_FAILED = "GET_CU_DATA_FAILED";

export const GET_PQA_DATA_SUCCESS = "GET_PQA_DATA_SUCCESS";
export const GET_PQA_DATA_FAILED = "GET_PQA_DATA_FAILED";

export const GET_USER_DATA_EDIT_SUCCESS = "GET_USER_DATA_EDIT_SUCCESS";
export const GET_USER_DATA_EDIT_FAILED = "GET_USER_DATA_EDIT_FAILED";

export const FILE_UPLOAD_STARTED = "FILE_UPLOAD_STARTED";
export const FILE_UPLOAD_COMPLETED = "FILE_UPLOAD_COMPLETED";
