export const REDIRECT_URL = "REDIRECT_URL";
export const REDIRECT_TYPE = "REDIRECT_TYPE";
export const AZUR_TOKEN = "AZUR_TOKEN";

export const ORGNIZATION_REGION = [
  { label: "North America", value: "North America" },
  { label: "Europe", value: "Europe" },
  { label: "South America", value: "South America" },
  { label: "Asia pacific ", value: "Asia pacific" },
];

export const ORGNIZATION_PROGRAM_TYPE = [
  { label: "State", value: "State" },
  { label: "Part B - IDEA", value: "Part B - IDEA" },
  {
    label: "Part C - IDEA Early Intervention",
    value: "Part C - IDEA Early Intervention",
  },
  { label: "Head Start", value: "Head Start" },
  { label: "Early Head Start", value: "Early Head Start" },
  { label: "Tuition Pay (Full)", value: "Tuition Pay (Full)" },
  { label: "Tuition Pay (Partial)", value: "Tuition Pay (Partial)" },
  { label: "Other", value: "Other" },
];

export const ORGNIZATION_LEVEl = [
  { label: "Country", value: "Country" },
  { label: "State", value: "State" },
  {
    label: "District",
    value: "District",
  },
  { label: "School", value: "School" },
  { label: "Classrooms", value: "Classrooms" },
];

export const SUBSCRIPTION_TYPE = [{ label: "Yearly", value: "Yearly" }];

export const USER_ROLES = {
  HIGHSCOPE_ADMIN: "HighscopeAdmin",
  HIGHSCOPE_USER: "User",
  HIGHSCOPE_ORG_USER: "HighscopeOrgAdmin",
};
