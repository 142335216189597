import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function BootstrapModal({ isVisble, handleClose, modalHeader, modalBody, footerBody, }: any) {


  return (
    <Modal size='xl' show={isVisble} onHide={handleClose}>
      <Modal.Header className={'p-1 m-0 rounded-b-none bg-[#264390] rounded-t-xl text-white'} >
        <Modal.Title className={``}>{modalHeader}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`overflow-auto h-[500px]`}>{modalBody}</Modal.Body>

      <Modal.Footer className={'!bg-[#264390]  rounded-b-xl text-white '}>
        {footerBody}

      </Modal.Footer>
    </Modal>
  );
}

export default BootstrapModal;