import { Container } from "react-bootstrap";
import {
  ArrowUturnDownIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Image } from "react-bootstrap";
import TextInput from "../components/Input";
import { useState, useEffect } from "react";
import { validateEmailId } from "../utils";
import { getUserData, updateUserDetails } from "../redux/actions/user";
import { useAppDispatch } from "../hooks/app";
import HSSelect from "../components/Select";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getAllOrgnizationForDropdown } from "../redux/actions/orgnization";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { getAllApplications } from "../redux/actions/application";
import { cloneDeep } from "lodash";
import { useNavigate, useParams } from "react-router";

export const EditHSAdmin = () => {
  const { id } = useParams();
  const [orgDetails, setOrgDetails] = useState<any>("");
  const [rowData, setRowData] = useState<any>();

  const userEditDetails = useSelector(
    (state: RootState) => state?.user?.userEditDetails
  );

  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [applications, setApplications] = useState<Array<any>>([]);
  const [open, setOpen] = useState<number>(1);
  const dispatch = useAppDispatch();
  const applicationList = useSelector(
    (state: RootState) => state.application.appList
  );
  const org = useSelector((state: RootState) => state.organization);

  const [selectedRole, setSelectedRole] = useState<{ value: string } | null>(
    null
  );
  const [externalError, setExternalError] = useState("");

  const navigate = useNavigate();

  const initialApplication = {
    id: "",
    appId: -1,
    headerTitle: "",
    logo: "",
    code: "",
    role: "",
    error: "",
    userDetails: {
      applicationId: undefined,
      applicationRoleId: undefined,
      mainRole: "",
      orgList: [],
      isDelete: false,
    },
  };

  const roles = [
    { label: "Highscope Admin", value: "hsadmin" },
    { label: "External Assessor", value: "external_accessor" },
  ];

  useEffect(() => {
    dispatch(getAllOrgnizationForDropdown());
    dispatch(getAllApplications());
  }, []);

  const menuList = [
    {
      id: 3,
      name: "Classroom Coach",
      logo: "/images/classroomCoach.webp",
      applicationCode: "COR-C",
    },
    {
      id: 6,
      name: "Program Quality Assessment",
      logo: "/images/logoPQA.webp",
      applicationCode: "COR-P",
    },
  ];

  const onChangeUserDetails = (value: any, type: any) => {
    if (type === "firstName") {
      setFirstName(value);
    } else if (type === "lastName") {
      setLastName(value);
    } else if (type === "middleName") {
      setMiddleName(value);
    } else if (type === "email") {
      setEmail(value);
    } else if (type === "userName") {
      setUserName(value);
    } else if (type === "phone") {
      setPhoneNumber(value);
    }
  };

  const updateUser = () => {
    if (!firstName) {
      setFirstNameError("Please enter first name");
      return;
    }
    if (!lastName) {
      setLastNameError("Please enter last name");
      return;
    }

    if (!userName) {
      setUserNameError("Please enter user name");
      return;
    }
    if (!phoneNumber) {
      setPhoneError("Please enter phone");
      return;
    }

    if (phoneNumber && phoneNumber.length !== 10) {
      setPhoneError("Phone number must be 10 digit");
      return;
    }

    if (!email) {
      setEmailError("Please enter email");
      return;
    }

    if (!validateEmailId(email)) {
      setEmailError("Please enter valid email");
      return;
    }
    const temAppList: any = [];
    const tempvalue: any = [];
    let isErrorOccured = false;
    let isApplicationAvailable = false;

    if (applications.length > 0) {
      for (let i = 0; i < applications.length; i++) {
        const userDetailObj = applications[i]?.userDetails;
        if (applications[i].dbId) {
          userDetailObj["id"] = applications[i]?.dbId;
        }
        userDetailObj["code"] = applications[i]?.code;
        userDetailObj["applicationId"] =
          rowData?.userDetails[i]?.application?.id;
        userDetailObj["applicationRoleId"] =
          rowData?.userDetails[i]?.application_role?.id;
        userDetailObj["mainRole"] =
          rowData?.userDetails[i]?.application_role?.role_name;
        userDetailObj["orgList"] = applications[i]?.orgList;

        temAppList.push(userDetailObj);
        tempvalue.push(applications[i]);
        if (!userDetailObj.isDelete) {
          isApplicationAvailable = true;
        }
      }
    }

    if (selectedRole?.value === "external_accessor") {
      if (temAppList.filter((tapp: any) => !tapp.isDelete).length === 0) {
        setExternalError("Please select/add application or delete the user");
        return;
      }
      for (var key in applications) {
        if (tempvalue[key]?.userDetails?.orgList?.length === 0) {
          if (!tempvalue[key]?.userDetails?.isDelete) {
            isErrorOccured = true;
            tempvalue[key].error = "Please select organization";
          }
        } else if (!tempvalue[key]?.userDetails?.orgList) {
          isErrorOccured = true;
          tempvalue[key].error = "Please select organization";
        } else tempvalue[key].error = "";
        setApplications(tempvalue);
      }
    }
    if (isErrorOccured) {
      setApplications(tempvalue);
      return;
    }

    const body = {
      id: rowData.id,
      lastName: lastName,
      firstName: firstName,
      middleName: middleName,
      orgnizationId: null,
      role: selectedRole?.value === "hsadmin" ? "HighscopeAdmin" : "User",
      email: email,
      username: userName,
      userDetails: temAppList,
      phone: phoneNumber,
      azurId: rowData.azurId,
    };
    dispatch(updateUserDetails(body));
    navigate("/users");
  };

  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

  const isSelected = (menuId: any) => {
    return applications.findIndex(
      (element: any) => element.code === menuId.applicationCode
    );
  };

  const onSelectOption = (value: any, type: "DELETE" | "ADD" | "UNDO") => {
    const temAppList = [...applications];
    if (type === "ADD" && isSelected(value) === -1) {
      const menuData = menuList.find(
        (e) => e.applicationCode === value.applicationCode
      );
      const tempIntialApp = { ...initialApplication };
      tempIntialApp.appId = menuData?.id || -1;
      tempIntialApp.id = value.applicationCode;
      tempIntialApp.headerTitle = menuData?.name || "";
      tempIntialApp.logo = menuData?.logo || "";
      tempIntialApp.code = value.applicationCode;
      tempIntialApp.role = value.applicationRole;
      initialApplication.error = "";
      temAppList.push(tempIntialApp);
      setApplications(temAppList);
      handleOpen(applications.length);
    } else if (type === "DELETE" || type === "UNDO") {
      const index = temAppList.findIndex((obj: any) => obj.dbId === value.dbId);
      if (type === "DELETE") {
        if (temAppList[index].dbId) {
          temAppList[index].userDetails.isDelete = true;
        } else {
          temAppList.splice(index, 1);
        }
      } else {
        temAppList[index].userDetails.isDelete = false;
      }
      setApplications(temAppList);
    }
  };

  const onOrgnizationSelected = (index: number, obj: any) => {
    const templist: any[] = [...applications];
    const extractedFields = obj.map((item: any) => ({
      id: item.id,
      name: item.name,
      uuid: item.uuid,
    }));
    templist[index].orgList = [...extractedFields];
    setApplications(templist);
  };

  const isValidUserDetails = () => {
    if (
      firstName &&
      lastName &&
      // middleName &&
      email &&
      userName &&
      phoneNumber &&
      selectedRole
    )
      return true;
    return false;
  };

  const toggleHeader = (value: number) => {
    if (open === value) {
      handleOpen(-1);
    } else {
      handleOpen(value);
    }
  };

  useEffect(() => {
    if (rowData) {
      setFirstName(rowData.firstName);
      setLastName(rowData.lastName);
      setEmail(rowData.email);
      setUserName(rowData.username);
      setMiddleName(rowData.middleName);
      setPhoneNumber(rowData.phone);
      if (rowData.role.length) {
        if (rowData.role === "HighscopeAdmin") {
          setSelectedRole(roles[0]);
        } else {
          setSelectedRole(roles[1]);
        }
      }
    }
  }, [rowData]);

  useEffect(() => {
    if (userEditDetails) {
      dispatch(getAllOrgnizationForDropdown());
      setRowData(userEditDetails);
      prePopulateData(userEditDetails);
    }
  }, [userEditDetails]);

  const getRoleList = (code: any) => {
    const list = orgDetails?.applications?.filter(
      (item: any) => item?.applicationCode == code
    );
    return list?.[0]?.applicationRole || [];
  };

  const prePopulateData = (rowData: any) => {
    const temAppList: any = [];
    for (let i = 0; i < rowData.userDetails.length; i++) {
      const appDetails = rowData.userDetails[i];
      const { application, application_role } = appDetails;
      const menuData = getMenuListDataByCode(application.applicationCode);
      const param: any = cloneDeep(initialApplication);
      param.appId = application?.id || -1;
      param.id = application.applicationCode;
      param.headerTitle = application?.applicationName || "";
      param.logo = menuData?.logo || "";
      param.code = application.applicationCode;
      param.roles = getRoleList(application.applicationCode);
      param.selectedRole = application_role;
      param.isDelete = false;
      param.dbId = appDetails.id;
      // delete application_role["application"];
      temAppList.push({ ...appDetails, ...param });
    }
    setApplications(temAppList);
  };

  const getMenuListDataByCode = (code: string | undefined) => {
    return menuList.find((e) => e.applicationCode === code);
  };
  useEffect(() => {
    if (org.orgData) {
      setOrgDetails(org?.orgData);
    }
  }, [org?.orgData]);

  useEffect(() => {
    if (id) {
      dispatch(getUserData(id));
    }
  }, [id]);

  const getOrgList = (value: string) => {
    return [...org?.orgList].filter(
      (orgItem: any) =>
        orgItem.applications &&
        orgItem.applications.some((app: any) => app.applicationCode === value)
    );
  };

  return (
    <>
      <Container className="h-full bg-white p-4 min-w-[82vw] font-['poppins'] overflow-auto">
        <div className="h-full res_height ">
          <div className="flex flex-row justify-center  bg-[#264390] max-w-[300px]  mx-auto text-white font-medium p-2 min-h-fit max-h-full rounded-sm ">
            <UserCircleIcon className="w-8 h-8 mr-2 " />
            <span className="text-2xl font-medium ">Edit HS Admin</span>
          </div>
          <div className="Card my-3 rounded-md shadow-sm bg-[#ececec]">
            <div className="p-4 xl:h-[57vh] 2xl:h-[65vh] 1xl:h-[58vh] lg:h-[55vh] md:h-[55vh] overflow-auto">
              <span className="font-semibold text-xl">Enter User Details</span>
              <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 px-1 py-3 gap-y-1 gap-x-5">
                <TextInput
                  placeholder={"First Name"}
                  required={true}
                  error={firstNameError}
                  value={firstName}
                  onChange={(e: any) => {
                    onChangeUserDetails(e.target.value, "firstName");
                    setFirstNameError("");
                  }}
                />
                <TextInput
                  placeholder="Middle Name"
                  required={false}
                  error={middleNameError}
                  value={middleName}
                  onChange={(e: any) => {
                    onChangeUserDetails(e.target.value, "middleName");
                    setMiddleNameError("");
                  }}
                />
                <TextInput
                  placeholder="Last Name"
                  required={true}
                  error={lastNameError}
                  value={lastName}
                  onChange={(e: any) => {
                    onChangeUserDetails(e.target.value, "lastName");
                    setLastNameError("");
                  }}
                />
                <TextInput
                  placeholder="Email Address"
                  required={true}
                  disabled={true}
                  value={email}
                  error={emailError}
                  onChange={(e: any) => {
                    onChangeUserDetails(e.target.value, "email");
                    setEmailError("");
                  }}
                />
                <TextInput
                  placeholder="Username"
                  required={true}
                  disabled={true}
                  error={userNameError}
                  value={userName}
                  onChange={(e: any) => {
                    onChangeUserDetails(e.target.value, "userName");
                    setUserNameError("");
                  }}
                />
                <TextInput
                  placeholder={"Phone"}
                  required={true}
                  value={phoneNumber}
                  error={phoneError}
                  onChange={(e: any) => {
                    const re = /^[0-9\b]+$/;
                    if (
                      (re.test(e.target.value) && e.target.value.length < 11) ||
                      !e.target.value
                    ) {
                      onChangeUserDetails(e.target.value, "phone");
                      setPhoneError("");
                    }
                  }}
                />
                <HSSelect
                  label={"Select Role"}
                  placeholder="Role"
                  options={roles}
                  disabled={true}
                  isMulti={false}
                  closeMenuOnSelect={false}
                  required={true}
                  value={selectedRole}
                  onChange={(obj: any) => {
                    setExternalError("");
                    setSelectedRole(obj);
                  }}
                />
                {selectedRole && selectedRole.value === "external_accessor" && (
                  <>
                    {applicationList.length > 0 && (
                      <div className="  h-12 mt-[20px]">
                        <Menu>
                          <MenuHandler>
                            <Button
                              className="flex flex-row items-center  bg-[#264390] w-full justify-center h-12"
                              disabled={!isValidUserDetails()}
                            >
                              <ChevronDownIcon className="w-7 h-7 mr-2 mt-1" />
                              <Typography className="text-[15px] font-normal mt-3">
                                {" "}
                                Add Application Access
                              </Typography>
                            </Button>
                          </MenuHandler>

                          <MenuList
                            className={`w-[330px] p-0  bg-[#264390] text-white boredr border-white rounded-none -mt-2 `}
                          >
                            {applicationList &&
                              applicationList.map(
                                (menu: any, index: number) => {
                                  if (
                                    menu.applicationCode === "COR-C" ||
                                    menu.applicationCode === "COR-P"
                                  ) {
                                    return (
                                      <MenuItem
                                        key={index}
                                        className={`border-b border-white rounded-none
                              ${
                                isSelected(menu) === -1
                                  ? " "
                                  : "bg-gray-200 text-gray-600 rounded-none "
                              }`}
                                        onClick={() => {
                                          setExternalError("");
                                          onSelectOption(menu, "ADD");
                                        }}
                                      >
                                        {menu.applicationName}
                                      </MenuItem>
                                    );
                                  }
                                }
                              )}
                          </MenuList>
                        </Menu>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="px-4">
                {externalError && (
                  <span className="text-[15px] 2xl:text-[18px] flex justify-center px-5 text-red-500">
                    {externalError}
                  </span>
                )}
                {applications?.map((obj: any, index: number) => (
                  <Accordion
                    open={open === index}
                    className="mb-2 rounded-lg border border-blue-gray-100 text-center shadow-xl "
                  >
                    <AccordionHeader
                      onClick={() => toggleHeader(index)}
                      className={`bg-[#264390] text-white w-[full] ${
                        open === index
                          ? "rounded-lg rounded-b-none"
                          : "rounded-lg "
                      }   h-[40px] flex flex-row justify-between`}
                    >
                      <div className=" w-full flex flex-row justify-between items-center">
                        <p className="mt-3 px-4 flex flex-row">
                          {obj.headerTitle}
                        </p>
                        <div className=" flex flex-row">
                          <div className="flex text-white">
                            {obj?.userDetails?.isDelete ? (
                              <ArrowUturnDownIcon
                                className="w-8 h-8 bg-[#264390] p-1"
                                onClick={(event) => {
                                  onSelectOption(obj, "UNDO");
                                  event.stopPropagation();
                                }}
                              />
                            ) : (
                              <div className="flex text-white">
                                <PencilSquareIcon className="w-8 h-8 mr-3 bg-[#264390] p-1" />
                                <TrashIcon
                                  className="w-8 h-8 bg-[#264390] p-1"
                                  onClick={(event) => {
                                    onSelectOption(obj, "DELETE");
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <ChevronDownIcon className="w-8 h-8 ml-2 items-end" />
                        </div>
                      </div>
                    </AccordionHeader>
                    <AccordionBody className="pt-0 text-base font-normal bg-white relative  min-h-[200px] max-h-[300px] overflow-auto">
                      <div className="flex sm:flex-col md:flex-col py-3 px-3 mx-4 items-center md:gap-0 sm:gap-0 gap-4 ">
                        <Image
                          src={obj?.logo}
                          width={180}
                          height={70}
                          alt="logo"
                        />
                        <div className="w-full text-left">
                          <div className="flex flex-col ml-24">
                            <HSSelect
                              label={"Select Organization"}
                              placeholder="Select Organization"
                              options={getOrgList(
                                obj?.code === "COR-C" ? "COR-C" : "COR-P"
                              )}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              required={true}
                              error={obj.error}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option.id}
                              value={applications[index].orgList}
                              onChange={(obj: any) => {
                                onOrgnizationSelected(index, obj);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </AccordionBody>
                  </Accordion>
                ))}
              </div>
            </div>

            <div
              className={`flex justify-end px-5 border-t-2 border-gray-400 py-2 h-[60px]`}
            >
              <Button
                className="flex flex-row items-center justify-center bg-[#264390] hover:bg-gray-500   py-1 px-3 2xl:px-4 2xl:py-3 rounded-md"
                onClick={updateUser}
              >
                <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                  Update
                </span>
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
