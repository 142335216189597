import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { clearRedirectSession, getRedirectData, setAzurToken } from "../utils";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const AuthComplete = () => {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const activeAccount: any = instance.getActiveAccount();
  const [isRedirected, setRedirected] = useState(false);
  const [idToken, setIdToken] = useState();
  const [cookiesUrl, setRedirectUrlCookie, removeUrlCookie] = useCookies([
    "redirectData",
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const params = new URLSearchParams(location.hash);
      if (params.get("error_description")?.includes("AADB2C90091")) {
        navigate("/");
      } else if (params.get("error_description")?.includes("AADB2C90118")) {
        navigate("/");
        // const authority = process.env.REACT_APP_AZURE_RESET_PASSWORD_AUTHORITY;
        // instance
        //   .loginRedirect({
        //     authority: authority,
        //     scopes: ["offline_access"],
        //   })
        //   .catch((error) => console.log(error));
      }
    }
  }, [location]);

  //testing 
  useEffect(() => {
    if (activeAccount && cookiesUrl && inProgress === "none") {
      if (activeAccount.idToken) {
        openNextApp(activeAccount.idToken);
      } else {
        getTokenResponse();
      }
    }
    return () => {
      clearRedirectSession();
    };
  }, [activeAccount, inProgress]);

  useEffect(() => {
    if (idToken) {
      openNextApp(idToken);
    }
  }, [idToken]);

  const openNextApp = (token: string) => {
    setAzurToken(token);
    if (
      cookiesUrl.redirectData &&
      cookiesUrl.redirectData.redirectUrl &&
      cookiesUrl.redirectData.type
    ) {
      setRedirected(true);
      const url =
        cookiesUrl.redirectData.type === "resetPass"
          ? cookiesUrl.redirectData.redirectUrl
          : cookiesUrl.redirectData.redirectUrl + "?token=" + token;
      window.open(url, "_self");
      removeUrlCookie("redirectData");
    } else {
      if (!isRedirected) {
        window.open("/", "_self");
      }
    }
  };

  const getTokenResponse = async () => {
    const tokenResponse: any = await instance.acquireTokenSilent({
      account: activeAccount,
      scopes: ["offline_access"],
    });
    setIdToken(tokenResponse.idToken);
  };

  return (
    <>
      <div>Auth Complete</div>
    </>
  );
};
