import React from "react";
import { Chip } from "@material-tailwind/react";

interface Role {
  role_name: string;
  role_id: number;
  sort_order: number;
  id: number;
  isSubDropDown: boolean;
  isDisabled?: boolean;
}

interface ChipsProps {
  roles: Role[];
  selectedRole: Role | null;
  onSelectRole: (role: Role) => void;
  header: string;
}

export function Chips({
  roles,
  selectedRole,
  onSelectRole,
  header,
}: ChipsProps) {
  return (
    <div className="flex flex-col justify-center py-1 px-1 gap-0">
      <div className="flex">
        <span className="font-bold text-lg whitespace-break-spaces">
          {header}
        </span>
      </div>
      <div className="grid grid-cols-4  md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 pb-3 gap-2">
        {roles
          .sort((a, b) => {
            return a.sort_order - b.sort_order;
          })
          .map((role) => (
            <div
              key={role.role_id}
              className={`shadow-md rounded-xl ${
                role.isDisabled
                  ? "cursor-not-allowed bg-gray-300"
                  : "cursor-pointer"
              }`}
              onClick={() => !role.isDisabled && onSelectRole(role)}
            >
              <Chip
                variant="outlined"
                color={
                  selectedRole && selectedRole.role_id === role.role_id
                    ? "blue"
                    : role.isDisabled
                    ? "gray"
                    : "cyan"
                }
                value={role.role_name}
                className={`h-12 w-auto whitespace-break-spaces ${
                  selectedRole && selectedRole.role_id === role.role_id
                    ? "bg-blue-500 text-white"
                    : role.isDisabled
                    ? "text-gray-500"
                    : "text-blue-500 bg-white"
                }`}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
