import { Image } from "react-bootstrap";
import logo from "../images/logoRSA.webp";
import { useEffect, useState } from "react";
import { Chips } from "../../components/Chip";
import HSSelect from "../../components/Select";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useAppDispatch } from "../../hooks/app";
import { getOrgDetailsFromCU } from "../../redux/actions/orgnization";

interface Role {
  role_name: string;
  role_id: number;
  id: number;
  isSubDropDown: boolean;
  sort_order: number;
}
const CurriculumAssessmentComponent = (props: {
  roles: Role[];
  org: any;
  onDataChange: Function;
  data?: any;
  isChipSelected?: (e: any) => void;
  orgRole: any;
}) => {
  const dispatch = useAppDispatch();
  const [mainRole, setMainRole] = useState<Role | null>(null);
  const [sites, setSites] = useState<any>([]);
  const [classrooms, setClassrooms] = useState<any>([]);
  const [selectedSite, setSelectedSite] = useState<any | undefined>();
  const [selectedClassroom, setSelectedClassroom] = useState<any | undefined>();
  const [mainRoleList, setMainRoleList] = useState<any>([]);
  const [disableAll, setDisableAll] = useState<boolean | false>();

  const org = useSelector((state: RootState) => state.organization);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    const mainRoleData: Role[] = [
      ...props.roles.filter(
        (role) => !role.isSubDropDown && role.role_id !== 100000
      ),
    ];

    const cuData = userDetails?.userDetails?.find(
      (userDetail: any) => userDetail?.application.applicationCode === "COR-CU"
    );
    const userDetailsSites = cuData?.siteDetails?.map(
      (site: any) => site.siteId
    );
    const userDataSites = props?.data?.siteDetails?.map(
      (site: any) => site.siteId
    );
    const userDetailsClasses = cuData?.classDetails?.map(
      (clss: any) => clss.classId
    );
    const userDataClasses = props?.data?.classDetails?.map(
      (clss: any) => clss.classId
    );

    const isSubsetSite =
      userDetailsSites && userDataSites
        ? userDataSites.every((siteId: any) =>
            userDetailsSites.includes(siteId)
          )
        : false;

    const isSubsetClass =
      userDetailsClasses && userDataClasses
        ? userDataClasses.every((classId: any) =>
            userDetailsClasses.includes(classId)
          )
        : false;

    const orgRoleToCompare = props.orgRole || 0;
    const selectedRoleToCompare =
      props.data && props.data.selectedRole
        ? props.data.selectedRole.role_id
        : 0;
    const isCreatingUser = !props.data || !props.data.selectedRole;
    if (!isCreatingUser && orgRoleToCompare !== 100000) {
      if (orgRoleToCompare === 100004) {
        setDisableAll(true);
      }
      if (orgRoleToCompare === 100002 && selectedRoleToCompare === 100001) {
        setDisableAll(true);
      }
      if (orgRoleToCompare === 100002 && selectedRoleToCompare === 100002) {
        setDisableAll(!isSubsetSite);
      }
      if (orgRoleToCompare === 100002 && selectedRoleToCompare === 100004) {
        setDisableAll(!isSubsetSite);
      }
    }
    if (orgRoleToCompare === 0) {
      setDisableAll(true);
    }

    const updatedMainRoleList: Role[] = mainRoleData.map((role) => {
      let isDisabled = false;
      if (isCreatingUser) {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100001:
            isDisabled = false;
            break;
          case 100002:
            isDisabled = role.role_id === 100001;
            const updatedSite = {
              ...cuData.siteDetails[0],
              _id: cuData.siteDetails[0].siteId,
              Site_Name: cuData.siteDetails[0].siteName,
            };
            setSelectedSite(updatedSite);
            break;
          case 100004:
            isDisabled = true;
            break;
          default:
            isDisabled = true;
            break;
        }
      } else {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100001:
            isDisabled = false;
            break;
          case 100002:
            isDisabled =
              selectedRoleToCompare === 100001 ||
              (selectedRoleToCompare === 100002 && role.role_id === 100001) ||
              (selectedRoleToCompare === 100002 && !isSubsetSite) ||
              (selectedRoleToCompare === 100004 && !isSubsetSite) ||
              (selectedRoleToCompare === 100004 && role.role_id === 100001);
            const updatedSite = {
              ...cuData.siteDetails[0],
              _id: cuData.siteDetails[0].siteId,
              Site_Name: cuData.siteDetails[0].siteName,
            };
            setSelectedSite(updatedSite);
            break;
          case 100004:
            isDisabled = true;
            break;
          default:
            isDisabled = true;
            break;
        }
      }

      return {
        ...role,
        isDisabled,
      };
    });
    setMainRoleList(updatedMainRoleList);
  }, [props, userDetails]);

  useEffect(() => {
    if (props?.org) {
      const body = {
        hs_id: props?.org.id,
        hs_uuid: props?.org.uuid,
        applications: "COR-CU",
        action: "HS_GET_DETAILS",
      };
      dispatch(getOrgDetailsFromCU(body));
    }
  }, [props?.org]);

  const handleSelectRole = (role: Role) => {
    setMainRole(role);
    setSelectedSite(null);
    setSelectedClassroom(null);
    setClassrooms([]);
    props.isChipSelected && props.isChipSelected(true);
  };

  const handleDataChange = (data: any) => {
    props.onDataChange(data);
  };

  useEffect(() => {
    if (props.data) {
      if (props?.data?.application_role) {
        handleSelectRole(props?.data?.application_role);
      }

      if (props?.data?.siteDetails && props.data.siteDetails.length > 0) {
        const data: any = [];
        for (let i = 0; i < props.data.siteDetails.length; i++) {
          const obj = {
            Site_Name: props.data.siteDetails[i].siteName,
            _id: props.data.siteDetails[i].siteId,
          };
          data.push(obj);
        }
        setSelectedSite(data[0]);
      }
      if (props?.data?.classDetails && props.data.classDetails.length > 0) {
        const cdata: any = [];
        for (let i = 0; i < props.data.classDetails.length; i++) {
          const obj = {
            Classroom_Name: props.data.classDetails[i].className,
            _id: props.data.classDetails[i].classId,
          };
          cdata.push(obj);
        }
        setSelectedClassroom(cdata);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (org?.CUData?.entity_data) {
      setSites(org?.CUData?.entity_data?.sites);
    }
  }, [org?.CUData?.entity_data]);

  useEffect(() => {
    if (selectedSite && sites) {
      const matchedSite = sites.find(
        (site: any) => site._id === selectedSite._id
      );
      const userCorA = userDetails?.userDetails?.find(
        (userDetail: any) =>
          userDetail?.application.applicationCode === "COR-CU"
      );
      const userRole = userCorA?.application_role?.role_id;

      if (matchedSite) {
        if (userRole !== 100004) {
          setClassrooms(matchedSite.classes ? matchedSite.classes : []);
        } else {
          const filteredClassRooms = matchedSite.classes.filter((clss: any) =>
            userCorA?.classDetails.some(
              (userClass: any) => userClass.classId === clss._id
            )
          );
          setClassrooms(filteredClassRooms ? filteredClassRooms : []);
        }
      }
    }
  }, [selectedSite, sites, userDetails]);

  useEffect(() => {
    handleDataChange({
      mainRole,
      selectedSite,
      selectedClassroom,
    });
  }, [mainRole, selectedSite, selectedClassroom]);

  return (
    <>
      <div className="grid grid-cols-6 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 overflow-auto">
        <Image
          className="px-3 py-2"
          src={logo}
          width={180}
          height={70}
          alt="Curriculum"
        />
        <div className="grid md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 items-center col-span-5">
          <Chips
            header={"Select Role in the Organization"}
            roles={mainRoleList}
            selectedRole={mainRole}
            onSelectRole={handleSelectRole}
          />
        </div>
      </div>
      {disableAll && (
        <div className="text-yellow-700 font-semibold">
          ***You are not allowed to modify this user's details!!!
        </div>
      )}

      <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
        {mainRole?.role_id !== 100000 && mainRole?.role_id !== 100001 && (
          <div className="w-full pl-5  text-left">
            {mainRole ? (
              <div className="w-full pl-5  text-left">
                <HSSelect
                  label={"Sites"}
                  placeholder="Select Site"
                  options={sites}
                  isMulti={false}
                  closeMenuOnSelect={false}
                  required={true}
                  getOptionLabel={(option: any) => option.Site_Name}
                  getOptionValue={(option: any) => option._id}
                  value={selectedSite}
                  onChange={(obj: any) => {
                    setSelectedSite(obj);
                    setSelectedClassroom(null);
                  }}
                  disabled={
                    props.orgRole === 100002 ||
                    props.orgRole === 100004 ||
                    disableAll
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {mainRole?.role_id !== 100000 &&
          mainRole?.role_id !== 100001 &&
          mainRole?.role_id !== 100002 && (
            <div className="w-full pl-5  text-left">
              {mainRole ? (
                <div className="w-full pl-5  text-left">
                  <HSSelect
                    label={"Classrooms"}
                    placeholder="Select Classroom"
                    options={classrooms}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    required={true}
                    getOptionLabel={(option: any) => option.Classroom_Name}
                    getOptionValue={(option: any) => option._id}
                    value={selectedClassroom}
                    onChange={(obj: any) => {
                      setSelectedClassroom(obj);
                    }}
                    disabled={disableAll}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
      </div>
    </>
  );
};

export default CurriculumAssessmentComponent;
