import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Container } from "react-bootstrap";
import { IdTokenData } from "../components/DataDisplay";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { USER_ROLES } from "../utils/constant";

import NewProject from "./images/NewProject.png";

import { loginRequest } from "../authConfig";
import { EyeIcon, ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const MainDashboard = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const isUserLoggedIn = useIsAuthenticated();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleResetPassword = () => {
    const authority = process.env.REACT_APP_AZURE_RESET_PASSWORD_AUTHORITY;
    instance.loginRedirect({
      authority: authority,
      scopes: ["offline_access"],
    });
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      if (userDetails && userDetails.role === USER_ROLES.HIGHSCOPE_ADMIN) {
        navigate("/admin_dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }, [isUserLoggedIn]);

  return (
    <>
      <div
        id="background_branding_container"
        data-tenant-branding-background-color="true"
        className="h-full w-[full]  font-poppins "
        style={{
          backgroundImage: "url('images/bg-hs.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* <img 
      data-tenant-branding-background="true" 
      src="images/bg-hs.jpg" 
      alt="Background"/> */}
        <div className="w-[36.5%] xl:w-[34.1%]  1xl:w-[35%] mt-3  md:w-[40%] lg:w-[32%] sm:w-[15%] m-auto p-1 xl:p-5 container-box ">
          {/* <div className="w-full">
            <img src={logo} alt="Logo" className="text-center mx-auto" />
          </div> */}
          <div className=" bg-white mt-2 shadow-md">
            <div className="w-full text-center bg-[#264390] text-white p-2 ">
              <span className="font-bold 2xl:text-[33px] md:text-[26px] xl:text-[26px] lg:text-[20px]">
                Welcome To Highscope{" "}
              </span>
            </div>
            <div className="h-[50%]">
              <img
                src={"images/hslogo2.png"}
                width={80}
                alt="hs"
                className="text-center 2xl:h-[460px] h-[330px] w-[420px] mx-auto object-cover "
              />
            </div>
            <div className="w-full bg-[#264390] px-1 2xl:py-3 xl:py-2 1xl:py-2 lg:py-2 md:p-2">
              {isUserLoggedIn ? (
                <>Opening Application...</>
              ) : (
                <>
                  <div className="flex items-center justify-center xl:py-1 2xl:py-1">
                    <div
                      className="bg-white 2xl:p-3  md:p-2 lg:py-1 lg:px-2 xl:py-2 xl:px-2  1xl:py-2 1xl:px-2 rounded-md mx-2 text-[#264390] font-semibold cursor-pointer flex gap-1"
                      onClick={() => {
                        handleLoginRedirect();
                      }}
                    >
                      <ArrowRightOnRectangleIcon className="w-5 md:w-7 mr-1" />
                      Login Application
                    </div>
                    <div
                      className="bg-white  2xl:p-3 md:p-2 lg:py-1 lg:px-2  xl:py-2 xl:px-2 1xl:py-2 1xl:px-2 rounded-md mx-2 text-[#264390] font-semibold cursor-pointer flex gap-1"
                      onClick={() => {
                        handleResetPassword();
                      }}
                    >
                      <EyeIcon className="w-5  md:w-7 mr-1" />
                      Forgot/Reset Password
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
