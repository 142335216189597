import {
  BuildingOfficeIcon,
  HomeIcon,
  UserGroupIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import logo from "../pages/images/logoHs2.png";
// import logo from "../pages/images/logo.png"

import { Link, useLocation } from "react-router-dom";
import { RootState } from "../redux/store";
import { USER_ROLES } from "../utils/constant";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useAppDispatch } from "../hooks/app";
import { requestCompleted, requestStarted } from "../redux/actions/app";
import { getUserDetails } from "../redux/actions/user";

function Sidebar() {
  const { instance, inProgress } = useMsal();
  const location = useLocation();
  const activeAccount: any = instance.getActiveAccount();
  const dispatch = useAppDispatch();
  const isUserLoggedIn = useIsAuthenticated();
  const state = useSelector((state: any) => state.app);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    if (inProgress !== "none") {
      let message = "Request is in progress";
      if (inProgress === "login") {
        message = "Login is in progress";
      }
      if (inProgress === "startup") {
        message = "Request is in progress";
      }
      if (inProgress === "logout") {
        message = "Logout is in progress";
      }
      dispatch(requestStarted(message));
    } else {
      dispatch(requestCompleted());
    }
  }, [inProgress]);
  useEffect(() => {
    if (inProgress == "none" && isUserLoggedIn) {
      dispatch(getUserDetails(activeAccount.idTokenClaims.oid));
    }
  }, [inProgress, isUserLoggedIn]);
  return (
    <div
      className={`bg-white  font-[poppins] ${
        state.isHidden ? "w-[6vw] " : "w-[15vw] "
      }  `}
    >
      <div className="flex items-center  align-top relative p-2 max-h-[8vh] min-h-[8vh] bg-gray-100">
        <Image
          // style={{backgroundColor:"red"}}
          // src="./logo.png"
          src={logo}
          width={350}
          height={50}
          alt="Highscope Logo"
        />
      </div>
      {userDetails && (
        <div className="max-h-[92vh]">
          <Link to="/dashboard" className="no-underline text-black">
            <div
              className={`flex py-2 px-3 items-center w-full h-full hover:bg-gray-200 text-sm font-medium border-b border-gray-300
               ${
                 (location.pathname === "/dashboard" ? "bg-gray-200" : "") ||
                 (location.pathname === "/admin_dashboard" ? "bg-gray-200" : "")
               } `}
            >
              <HomeIcon className="w-8 h-8 bg-[#264390] rounded-full text-white mr-2 p-1" />{" "}
              {!state.isHidden && "Home"}
            </div>
          </Link>

          {userDetails.role === USER_ROLES.HIGHSCOPE_ADMIN ||
          userDetails.role === USER_ROLES.HIGHSCOPE_ORG_USER ? (
            <Link to="/createUser" className="no-underline text-black">
              <div
                className={`flex py-2 px-3 items-center w-full h-full hover:bg-gray-200 text-sm font-medium border-b border-gray-300 ${
                  location.pathname === "/createUser" ? "bg-gray-200" : ""
                }`}
              >
                <UserPlusIcon className="w-8 h-8 bg-[#264390] rounded-full text-white mr-2 p-1" />{" "}
                {!state.isHidden && "Create User"}
                {/* <span className="block sm:hidden xs:hidden">Create User</span> */}
              </div>
            </Link>
          ) : null}
          {userDetails.role === USER_ROLES.HIGHSCOPE_ADMIN ||
          userDetails.role === USER_ROLES.HIGHSCOPE_ORG_USER ? (
            <Link to="/users" className="no-underline text-black">
              <div
                className={`flex py-2 px-3 items-center w-full h-full hover:bg-gray-200 text-sm font-medium border-b border-gray-300 ${
                  location.pathname === "/users" ? "bg-gray-200" : ""
                }`}
              >
                <UserGroupIcon className="w-8 h-8 bg-[#264390] rounded-full text-white mr-2 p-1" />{" "}
                {!state.isHidden && "User List"}
              </div>
            </Link>
          ) : null}

          {userDetails.role === USER_ROLES.HIGHSCOPE_ADMIN && (
            <>
              <Link to="/createOrg" className="no-underline text-black">
                <div
                  className={`flex py-2 px-3 items-center w-full h-full hover:bg-gray-200 text-sm font-medium border-b border-gray-300  ${
                    location.pathname === "/createOrg" ? "bg-gray-200" : ""
                  } `}
                >
                  <BuildingOfficeIcon className="w-8 h-8 bg-[#264390] rounded-full text-white mr-2 p-1" />{" "}
                  {!state.isHidden && "Create Organization"}
                </div>
              </Link>
              <Link to="/orgList" className="no-underline text-black">
                <div
                  className={`flex py-2 px-3 items-center w-full h-full hover:bg-gray-200 text-sm font-medium border-b border-gray-300  ${
                    location.pathname === "/orgList" ? "bg-gray-200" : ""
                  } `}
                >
                  <BuildingOfficeIcon className="w-8 h-8 bg-[#264390] rounded-full text-white mr-2 p-1" />{" "}
                  {!state.isHidden && "Organization List"}
                </div>
              </Link>
            </>
          )}
          {userDetails.role === USER_ROLES.HIGHSCOPE_ADMIN ? (
            <Link to="/createHSAdmin" className="no-underline text-black">
              <div
                className={`flex py-2 px-3 items-center w-full h-full hover:bg-gray-200 text-sm font-medium border-b border-gray-300 ${
                  location.pathname === "/createHSAdmin" ? "bg-gray-200" : ""
                } `}
              >
                <UserPlusIcon className="w-8 h-8 bg-[#264390] rounded-full text-white mr-2 p-1" />{" "}
                {!state.isHidden && "Create HS Admin"}
              </div>
            </Link>
          ) : null}
        </div>
      )}
    </div>
  );
}
export default Sidebar;
