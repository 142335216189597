import { Image } from "react-bootstrap";
import logo from "../images/coreA.webp";
import { useEffect, useState } from "react";
import { Chips } from "../../components/Chip";
import HSSelect from "../../components/Select";
import { useAppDispatch } from "../../hooks/app";
import {
  getOrgDetailsFromCorA,
  getUserDetailsFromCorA,
} from "../../redux/actions/orgnization";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useLocation } from "react-router-dom";
import GridTable from "../../components/Table";

interface Role {
  role_name: string;
  role_id: number;
  sort_order: number;
  id: number;
  isSubDropDown: boolean;
  isDisabled?: boolean;
  selectedUser?: string;
}

const CoreAdvantageComponent = (props: {
  roles: Role[];
  org: any;
  onDataChange: Function;
  data?: any;
  isChipSelected?: (e: any) => void;
  orgRole?: any;
  selectedUser?: any;
}) => {
  const dispatch = useAppDispatch();
  const [mainRoleList, setMainRoleList] = useState<any>([]);
  const [mainRole, setMainRole] = useState<Role | null>(null);

  const [selectedSites, setSelectedSites] = useState<any | null>(null);
  const [sites, setSites] = useState<any | undefined>();
  const [classrooms, setClassrooms] = useState<any | undefined>();
  const [disableAll, setDisableAll] = useState<boolean | false>();
  const [userDetailsCor, setUserDetailsCor] = useState([]);

  const [selectedClassrooms, setSelectedClassrooms] = useState<
    any | undefined
  >();
  const [selectedUserImport, setSelectedUserImport] = useState<any>("");

  const location = useLocation();

  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const org = useSelector((state: RootState) => state.organization);
  const userDetailsCorA = useSelector(
    (state: RootState) => state.organization.userDetailsCorA
  );

  const columns = [
    // { key: "id", name: "ID", sortable: true, draggable: true },
    { key: "First_Name", name: "First Name", sortable: true, draggable: true },
    { key: "Last_Name", name: "Last Name", sortable: true, draggable: true },
    { key: "Username", name: "Username", sortable: true, draggable: true },
    { key: "Email_Id", name: "Email Id", sortable: true, draggable: true },
    {
      key: "Phone_Number",
      name: "Phone Number",
      sortable: false,
      draggable: true,
    },
  ];

  useEffect(() => {
    const mainRoleData: Role[] = [
      ...props.roles.filter(
        (role) => !role.isSubDropDown && role.role_id !== 100000
      ),
    ];

    const corAData = userDetails?.userDetails?.find(
      (userDetail: any) => userDetail?.application.applicationCode === "COR-A"
    );
    const userDetailsSites = corAData?.siteDetails?.map(
      (site: any) => site.siteId
    );
    const userDataSites = props?.data?.siteDetails?.map(
      (site: any) => site.siteId
    );
    const userDetailsClasses = corAData?.classDetails?.map(
      (clss: any) => clss.classId
    );
    const userDataClasses = props?.data?.classDetails?.map(
      (clss: any) => clss.classId
    );

    const isSubsetSite =
      userDetailsSites && userDataSites
        ? userDataSites.every((siteId: any) =>
            userDetailsSites.includes(siteId)
          )
        : false;

    const isSubsetClass =
      userDetailsClasses && userDataClasses
        ? userDataClasses.every((classId: any) =>
            userDetailsClasses.includes(classId)
          )
        : false;

    const orgRoleToCompare = props.orgRole || 0;
    const selectedRoleToCompare =
      props.data && props.data.selectedRole
        ? props.data.selectedRole.role_id
        : 0;
    const isCreatingUser = !props.data || !props.data.selectedRole;
    if (!isCreatingUser && orgRoleToCompare !== 100000) {
      if (orgRoleToCompare === 100003) {
        setDisableAll(!isSubsetSite);
      } else if (
        orgRoleToCompare === 100003 &&
        selectedRoleToCompare === 100001
      ) {
        setDisableAll(true);
      } else if (
        orgRoleToCompare === 100004 &&
        selectedRoleToCompare === 100001
      ) {
        setDisableAll(true);
      } else if (
        orgRoleToCompare === 100004 &&
        selectedRoleToCompare === 100003
      ) {
        setDisableAll(true);
      } else if (orgRoleToCompare === 100004 && isSubsetSite) {
        setDisableAll(!isSubsetClass);
      } else if (orgRoleToCompare === 100004 && !isSubsetSite) {
        setDisableAll(!isSubsetSite);
      }
    }
    if (orgRoleToCompare === 0) {
      setDisableAll(true);
    }
    if (orgRoleToCompare === 100004 && isCreatingUser) {
      const updatedSite = {
        ...corAData.siteDetails[0],
        _id: corAData.siteDetails[0].siteId,
        Center_Name: corAData.siteDetails[0].siteName,
      };
      setSelectedSites(updatedSite);
    }

    const updatedMainRoleList: Role[] = mainRoleData.map((role) => {
      let isDisabled = false;
      if (isCreatingUser) {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100001:
            isDisabled = false;
            break;
          case 100003:
            isDisabled = role.role_id === 100001;
            break;
          case 100004:
            isDisabled = role.role_id !== 100004;
            break;
          default:
            isDisabled = true;
            break;
        }
      } else {
        switch (orgRoleToCompare) {
          case 100000:
            isDisabled = false;
            break;
          case 100001:
            isDisabled = false;
            break;
          case 100003:
            isDisabled =
              selectedRoleToCompare === 100001 ||
              (selectedRoleToCompare === 100003 && role.role_id === 100001) ||
              (selectedRoleToCompare === 100003 && !isSubsetSite) ||
              (selectedRoleToCompare === 100004 && !isSubsetSite) ||
              (selectedRoleToCompare === 100004 && role.role_id === 100001);
            break;
          case 100004:
            isDisabled =
              selectedRoleToCompare === 100001 ||
              selectedRoleToCompare === 100003 ||
              (selectedRoleToCompare === 100004 && role.role_id !== 100004) ||
              (selectedRoleToCompare === 100004 && !isSubsetSite) ||
              (selectedRoleToCompare === 100004 && !isSubsetClass);
            break;
          default:
            isDisabled = true;
            break;
        }
      }

      return {
        ...role,
        isDisabled,
      };
    });
    setMainRoleList(updatedMainRoleList);
  }, [props, userDetails]);

  useEffect(() => {
    if (userDetailsCorA && !mainRole) {
      setUserDetailsCor(userDetailsCorA.users);
    }
  }, [userDetailsCorA, !mainRole]);

  useEffect(() => {
    if (mainRole) {
      const filtered: any = userDetailsCorA?.users.filter(
        (user: any) => user.Role == mainRole.role_id
      );

      setUserDetailsCor(filtered);
    }
  }, [mainRole, userDetailsCorA?.users]);

  useEffect(() => {
    if (props?.org && location.pathname !== "/importUser") {
      const body = {
        hs_id: props?.org.id,
        hs_uuid: props?.org.uuid,
        applications: "COR-A",
        action: "HS_GET_DETAILS",
      };
      dispatch(getOrgDetailsFromCorA(body));
    }
  }, [props?.org]);

  useEffect(() => {
    if (props?.org) {
      const body = {
        hs_id: props?.org.id,
        hs_uuid: props?.org.uuid,
        applications: "COR-A",
        action: "HS_GET_USER_DETAILS",
      };
      dispatch(getUserDetailsFromCorA(body));
    }
  }, [props?.org]);

  const handleSelectRole = (role: Role) => {
    setMainRole(role);
    props.isChipSelected && props.isChipSelected(true);
    setSelectedUserImport("");
  };

  const handleDataChange = (data: any) => {
    props.onDataChange(data);
  };

  useEffect(() => {
    handleDataChange({
      mainRole,
      selectedClassrooms,
      selectedSites,
      selectedUserImport,
    });
  }, [mainRole, selectedClassrooms, selectedSites, selectedUserImport]);

  useEffect(() => {
    if (props.data) {
      if (props?.data.application_role) {
        handleSelectRole(props.data.application_role);
      }
      if (props?.data.siteDetails && props.data.siteDetails.length > 0) {
        const data: any = [];
        for (let i = 0; i < props.data.siteDetails.length; i++) {
          const obj = {
            ...props.data.siteDetails[i],
            Center_Name: props.data.siteDetails[i].siteName,
            _id: props.data.siteDetails[i].siteId,
          };
          data.push(obj);
        }
        if (props?.data?.application_role?.role_id === 100003) {
          setSelectedSites(data);
        } else if (props?.data?.application_role?.role_id === 100004) {
          setSelectedSites(data[0]);
        }
      }

      if (props?.data.classDetails && props.data.classDetails.length > 0) {
        const data: any = [];
        for (let i = 0; i < props.data.classDetails.length; i++) {
          const obj = {
            ...props.data.classDetails[i],
            Class_Name: props.data.classDetails[i].className,
            _id: props.data.classDetails[i].classId,
          };
          data.push(obj);
        }
        setSelectedClassrooms(data);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (org?.corAData?.entity_data) {
      if (userDetails.role === "HighscopeAdmin") {
        setSites(org?.corAData?.entity_data?.sites);
      }
      const userCorA = userDetails?.userDetails?.find(
        (userDetail: any) => userDetail?.application.applicationCode === "COR-A"
      );

      const userRole = userCorA?.application_role?.role_id;
      if (userRole !== 100003 && userRole !== 100004) {
        setSites(org?.corAData?.entity_data?.sites);
      } else {
        const filteredSites = org?.corAData?.entity_data?.sites.filter(
          (site: any) =>
            userCorA?.siteDetails.some(
              (userSite: any) => userSite.siteId === site._id
            )
        );
        setSites(filteredSites);
      }
    }
  }, [org?.corAData?.entity_data, userDetails]);

  useEffect(() => {
    if (selectedSites && sites) {
      const matchedSite = sites.find(
        (site: any) => site._id === selectedSites._id
      );

      const userCorA = userDetails?.userDetails?.find(
        (userDetail: any) => userDetail?.application.applicationCode === "COR-A"
      );
      const userRole = userCorA?.application_role?.role_id;

      if (matchedSite) {
        if (userRole !== 100004) {
          setClassrooms(matchedSite.classes);
        } else {
          const filteredClassRooms = matchedSite.classes.filter((clss: any) =>
            userCorA?.classDetails.some(
              (userClass: any) => userClass.classId === clss._id
            )
          );
          setClassrooms(filteredClassRooms);
        }
      }
    }
  }, [selectedSites, sites, userDetails]);

  return (
    <div className="overflow-auto relative">
      <div className="grid grid-cols-6 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
        <Image
          className="px-3 py-2"
          src={logo}
          width={180}
          height={70}
          alt="COR Advantage"
        />
        <div className="grid md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 items-center col-span-5">
          <Chips
            header={"Select Role for the Organization"}
            roles={mainRoleList}
            selectedRole={mainRole}
            onSelectRole={handleSelectRole}
          />
        </div>
      </div>
      {disableAll && (
        <div className="text-yellow-700 font-semibold">
          ***You are not allowed to modify this user's details!!!
        </div>
      )}
      {mainRole &&
        mainRole?.role_id !== 100000 &&
        location?.pathname !== "/importUser" && (
          <div className="grid grid-cols-3 md:grid-cols-1 pl-20 sm:pl-5 xs:pl-5 sm:grid-cols-1 xs:grid-cols-1 items-center pr-5 pt-2">
            {mainRole?.role_id !== 100001 && (
              <>
                <div className="w-full pl-5  text-left">
                  <HSSelect
                    label={`${mainRole?.role_id === 100004 ? "Site" : "Sites"}`}
                    placeholder={
                      mainRole?.role_id === 100004
                        ? "Select Site"
                        : "Select Sites"
                    }
                    options={sites}
                    isMulti={mainRole?.role_id === 100004 ? false : true}
                    required={true}
                    getOptionLabel={(option: any) => option.Center_Name}
                    getOptionValue={(option: any) => option._id}
                    value={selectedSites}
                    onChange={(obj: any) => {
                      setSelectedClassrooms(null);
                      setSelectedSites(obj);
                    }}
                    closeMenuOnSelect={
                      mainRole?.role_id === 100004 ? true : false
                    }
                    disabled={disableAll || props.orgRole === 100004}
                  />
                </div>
                {mainRole?.role_id === 100004 && (
                  <div className="w-full pl-5  text-left">
                    <HSSelect
                      label={"Classrooms"}
                      placeholder="Select Classrooms"
                      options={classrooms}
                      isMulti={true}
                      disabled={!selectedSites || disableAll}
                      required={true}
                      getOptionLabel={(option: any) => option.Class_Name}
                      getOptionValue={(option: any) => option._id}
                      value={selectedClassrooms}
                      onChange={(obj: any) => {
                        setSelectedClassrooms(obj);
                      }}
                      closeMenuOnSelect={false}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      {location?.pathname == "/importUser" && mainRole ? (
        <div className="m-4">
          <HSSelect
            label={"Select Users Import"}
            placeholder="Select Users Import"
            required={true}
            options={userDetailsCor}
            disabled={!userDetailsCor ? true : false}
            getOptionLabel={(option: any) => option.First_Name}
            getOptionValue={(option: any) => option.Last_Name}
            value={selectedUserImport}
            isMulti={true}
            onChange={(obj: any) => {
              // setApplications([]);
              setSelectedUserImport(obj);
              // onChangeUserDetails(obj, "organization");
              // setOrgDetails(obj);
            }}
          />
          <GridTable
            rowData={userDetailsCor}
            columnData={columns}
            showAction={false}
            hidePagination={true}
            hideSearch={true}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CoreAdvantageComponent;
